/* RESPONSIBLE TEAM: team-standalone */

export function isStandaloneRoute(route: string) {
  let standaloneRoutes = [
    'apps.app.billing',
    'apps.app.settings',
    'apps.app.standalone',
    'apps.app.knowledge-hub',
  ];
  return standaloneRoutes.some((standaloneRoute) => route.startsWith(standaloneRoute));
}
