/* RESPONSIBLE TEAM: team-workflows */

import {
  ALL_CHANNELS,
  ALL_CHANNELS_EXCEPT_EMAIL,
  ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL,
  ALL_CHANNELS_EXCEPT_PHONE_CALL,
  APP_TEMPLATE_WORKFLOWS,
  INTERCOM_CHANNELS,
  ONLY_PHONE_CALL_CHANNEL,
  STANDALONE_INTERCOM_MESSENGER_CHANNEL_ONLY,
  ZENDESK_SUNSHINE_CHANNEL_ONLY,
} from 'embercom/lib/operator/custom-bots/constants';

export const WORKFLOWS_SETUP_POPULAR_TEMPLATE_IDS = ['2015', '2014', '2008', '2028', '2013'];

export const WORKFLOWS_DISCOVERABILITY_END_USER_EXPERIENCE = {
  id: 8,
  translationKey: 'end-user-experience',
  color: 'orange',
  showcaseIds: ['2008', '2013', '2012', '2009'],
};

export const WORKFLOWS_SETUP_END_USER_EXPERIENCE = {
  id: 8,
  translationKey: 'end-user-experience',
  color: 'orange',
  showcaseIds: ['2008', '2013', '2012', '2009', '2005'],
};

export const WORKFLOWS_DISCOVERABILITY_TEAMMATE_EFFICIENCY = {
  id: 9,
  translationKey: 'teammate-efficiency',
  color: 'blue',
  showcaseIds: ['2004', '2028', '2010', '2011'],
};

export const WORKFLOWS_SETUP_TEAMMATE_EFFICIENCY = {
  id: 9,
  translationKey: 'teammate-efficiency',
  color: 'blue',
  showcaseIds: ['2004', '2028', '2010', '2011', '2007', '2003', '2040'],
};

export const WORKFLOWS_DISCOVERABILITY_FIN_WORKFLOWS = {
  id: 11,
  translationKey: 'fin-workflows-wd',
  color: 'fin-wd',
  showcaseIds: ['2014', '2016', '2015', '2017'],
};

export const WORKFLOWS_SETUP_FIN_WORKFLOWS = {
  id: 11,
  translationKey: 'fin-workflows-wd',
  color: 'fin-wd',
  showcaseIds: ['2014', '2016', '2015', '2017'],
  showNewPill: true,
};

export const WORKFLOWS_DISCOVERABILITY_PROACTIVE_SUPPORT = {
  id: 10,
  translationKey: 'proactive-support',
  color: 'mint',
  showcaseIds: ['2001'],
};

interface WDTemplateGroup {
  id: number;
  translationKey: string;
  color: string;
  showcaseIds?: string[];
  showNewPill?: boolean;
}

const WORKFLOWS_DISCOVERABILITY_TEMPLATE_GROUPS: WDTemplateGroup[] = [
  WORKFLOWS_DISCOVERABILITY_TEAMMATE_EFFICIENCY,
  WORKFLOWS_DISCOVERABILITY_END_USER_EXPERIENCE,
  WORKFLOWS_DISCOVERABILITY_FIN_WORKFLOWS,
  WORKFLOWS_DISCOVERABILITY_PROACTIVE_SUPPORT,
  APP_TEMPLATE_WORKFLOWS,
];

const WORKFLOWS_SETUP_UPDATES_TEMPLATE_GROUPS: WDTemplateGroup[] = [
  WORKFLOWS_SETUP_FIN_WORKFLOWS,
  WORKFLOWS_SETUP_TEAMMATE_EFFICIENCY,
  WORKFLOWS_SETUP_END_USER_EXPERIENCE,
  WORKFLOWS_DISCOVERABILITY_PROACTIVE_SUPPORT,
  APP_TEMPLATE_WORKFLOWS,
];

export const SUPPORTED_CHANNELS = {
  fin: ALL_CHANNELS_EXCEPT_PHONE_CALL,
  attributeCollector: ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL,
  callback: ONLY_PHONE_CALL_CHANNEL,
  applyRules: ALL_CHANNELS_EXCEPT_PHONE_CALL,
  addTagToConversation: ALL_CHANNELS,
  removeTagFromConversation: ALL_CHANNELS,
  tagUser: ALL_CHANNELS,
  removeTagFromUser: ALL_CHANNELS,
  setLanguageOverride: ALL_CHANNELS,
  assignConversation: ALL_CHANNELS_EXCEPT_PHONE_CALL,
  assignConversationToOwner: ALL_CHANNELS,
  snooze: ALL_CHANNELS,
  wait: ALL_CHANNELS_EXCEPT_PHONE_CALL,
  changeConversationPriority: ALL_CHANNELS,
  applyConversationSla: ALL_CHANNELS,
  workflowConnector: ALL_CHANNELS,
  note: ALL_CHANNELS,
  hangUp: ONLY_PHONE_CALL_CHANNEL,
  phoneHoldAndAssign: ONLY_PHONE_CALL_CHANNEL,
  forwardCall: ONLY_PHONE_CALL_CHANNEL,
  phoneCallCsatRequest: ONLY_PHONE_CALL_CHANNEL,
  voicemail: ONLY_PHONE_CALL_CHANNEL,
  disableComposer: INTERCOM_CHANNELS,
  closeConversation: ALL_CHANNELS_EXCEPT_PHONE_CALL,
  setTicketCustomState: ALL_CHANNELS,
  summarizeConversation: ALL_CHANNELS,
  classifyConversationAttribute: ALL_CHANNELS,
  chatMessage: ALL_CHANNELS,
  convertToTicket: ALL_CHANNELS_EXCEPT_PHONE_CALL,
  triggerWorkflow: ALL_CHANNELS_EXCEPT_PHONE_CALL,
  setExpectations: ALL_CHANNELS_EXCEPT_PHONE_CALL,
  freeInput: ALL_CHANNELS_EXCEPT_PHONE_CALL,
  conversationRatings: ALL_CHANNELS_EXCEPT_PHONE_CALL,
  sendTicket: ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL,
  messengerApp: ALL_CHANNELS_EXCEPT_PHONE_CALL,
  replyButtons: ALL_CHANNELS_EXCEPT_EMAIL,
  customObjectSelector: ALL_CHANNELS_EXCEPT_EMAIL_AND_PHONE_CALL,
  conditionalBranches: ALL_CHANNELS,
  answerTerminal: ALL_CHANNELS,
  sendToSalesforce: ALL_CHANNELS,
  createSalesforceCase: ALL_CHANNELS,
  sendToHubspot: ALL_CHANNELS,
  customActionTemplate: ALL_CHANNELS,
  sendToMarketo: ALL_CHANNELS,
  triggerMarketoCampaign: ALL_CHANNELS,
  notifySlackChannel: ALL_CHANNELS,
  setConversationDataAttribute: ALL_CHANNELS,
  setUserDataAttribute: ALL_CHANNELS,
  finCustom: ALL_CHANNELS,
  handoffToZendeskTicket: ZENDESK_SUNSHINE_CHANNEL_ONLY,
  handoffToUrl: STANDALONE_INTERCOM_MESSENGER_CHANNEL_ONLY,
};

export { WORKFLOWS_DISCOVERABILITY_TEMPLATE_GROUPS, WORKFLOWS_SETUP_UPDATES_TEMPLATE_GROUPS };
