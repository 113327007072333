/* RESPONSIBLE TEAM: team-reporting */
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { isPresent } from '@ember/utils';
import { trackAnalyticsEventForChart } from 'embercom/lib/reporting/analytics';
import moment from 'moment-timezone';
import PALETTE from '@intercom/pulse/lib/palette';

export default class DefaultHighchartConfig {
  setXAxisFormatter(fn) {
    this.config.xAxis.labels.formatter = fn;
  }

  setYAxisFormatter(fn) {
    this.config.yAxis.labels.formatter = fn;
  }

  setYAxisRanges(min, max) {
    if (isPresent(min)) {
      this.config.yAxis.min = min;
    }

    if (isPresent(max)) {
      this.config.yAxis.max = max;
    }
  }

  setTargetLine(value, formattedValue) {
    this.config.yAxis.plotLines = [
      {
        color: PALETTE['accent-fill'],
        width: 2,
        value,
        zIndex: 5,
        label: {
          align: 'left',
          verticalAlign: 'middle',
          x: -15,
          y: 2,
          useHTML: true,
          formatter() {
            return `
              <span class="flex text-on-accent font-medium rounded-lg bg-accent-fill whitespace-nowrap px-2 h-5 items-center text-center justify-center">
                ${formattedValue}
              </span>
            `;
          },
        },
      },
    ];
  }

  setTooltipFormatter(fn) {
    this.config.tooltip.formatter = function () {
      let element = this.point?.graphic?.element;
      if (element) {
        trackAnalyticsEventForChart(element, { action: 'hovered' });
      }
      return sanitizeHtml(fn.call(this)).string;
    };
  }

  setDataLabelFormatter(fn) {
    this.config.plotOptions.series.dataLabels.formatter = function () {
      return sanitizeHtml(fn.call(this)).string;
    };
  }

  setTimezone(timezone) {
    this.config.time = { timezone, moment };
  }

  setChartType(type) {
    this.config.chart.type = type;
  }

  setColors(colors) {
    this.config.colors = colors;
  }

  setXAxisType(type) {
    this.config.xAxis.type = type;

    if (type === 'datetime') {
      this.config.plotOptions.column.keys = ['x', 'y', 'name'];
    } else {
      this.config.plotOptions.column.keys = undefined;
    }
  }
}
