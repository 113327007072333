/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import PALETTE from '@intercom/pulse/lib/palette';
import FlexibleColumnChartConfig from 'embercom/lib/reporting/flexible/default-column-chart-config';

export default class CustomUsageColumnChartConfig extends FlexibleColumnChartConfig {
  setTargetLine(value, formattedValue) {
    this.config.yAxis.plotLines = [
      {
        color: PALETTE['text-muted'],
        width: 2,
        value,
        zIndex: 5,
        dashStyle: 'dash',
        label: {
          align: 'left',
          verticalAlign: 'middle',
          x: -15,
          y: 2,
          useHTML: true,
          formatter() {
            return `
              <span class="flex text-default bg-neutral-container font-medium rounded-lg whitespace-nowrap px-2 h-5 items-center text-center justify-center">
                ${formattedValue}
              </span>
            `;
          },
        },
      },
    ];
  }
}
