/* RESPONSIBLE TEAM: team-reporting */
import PALETTE from '@intercom/pulse/lib/palette';
import { mapXAxisLabel } from 'embercom/lib/reporting/flexible/label-formatter';
import { setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import {
  calculateSegmentedPercentageData,
  calculateSegmentedRatioData,
  shouldConvertNullsToZeros,
} from 'embercom/lib/reporting/flexible/data-response-helpers';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import { isPresent } from '@ember/utils';

export default class HighchartsDataBuilder {
  @service intl;
  @service appService;

  constructor(owner, dataConfig, viewConfig) {
    setOwner(this, owner);
    this.dataConfig = dataConfig;
    this.viewConfig = viewConfig;
    this.otherSeriesName = this.intl.t('reporting.series.other');
    this.isLineChart = this.viewConfig?.chartType === VISUALIZATION_TYPES.LINE;
    this.isAreaChart = this.viewConfig?.chartType === VISUALIZATION_TYPES.AREA;
  }

  getAllXValues(group) {
    return group.values.map((groupItem) => groupItem.value);
  }

  buildSeries(name, points) {
    let unMappedName = name;
    if (name !== this.otherSeriesName) {
      name = this.mapLabel(name);
    }
    let series = {
      name,
      legendLabel: name,
      data: this.formatData(points),
      unMappedName,
    };

    if (this.isLineChart || this.isAreaChart) {
      series.marker = {
        symbol: 'circle',
      };
    }
    return series;
  }

  formatData(points) {
    return Array.from(points.entries()).map(([x, y]) => {
      // we only need to map the x axis label for segmented charts, i.e. when yAxis is present
      if (this.dataConfig.xAxis.type === 'nominal' && isPresent(this.dataConfig.yAxis)) {
        let mappedX = mapXAxisLabel(this.dataConfig, this.viewConfig, x);
        return [mappedX.toString(), y, mappedX];
      } else {
        return [x, y, x];
      }
    });
  }

  buildSeriesForHorizontalBar(responses) {
    let numeratorKeys = this.sortKeys(responses[0].groups[0].values);
    let numeratorBuckets = this.getHorizontalBarBuckets(responses[0].groups[0]);
    let numeratorTotal = this.getHorizontalBarTotal(numeratorBuckets);
    let numeratorComparator = numeratorTotal;
    let denominatorTotal;

    if (
      responses.length === 2 &&
      (this.viewConfig.metrics?.[0]?.type === 'ratio' ||
        this.viewConfig.metrics?.[0]?.type === 'percentage')
    ) {
      let denominatorData = responses[1].groups[0];
      let denominatorBuckets = this.getHorizontalBarBuckets(denominatorData);
      denominatorTotal = this.getHorizontalBarTotal(denominatorBuckets);
      numeratorComparator = denominatorTotal;
    }

    let series = [];

    numeratorKeys.forEach((numeratorKey) => {
      let numeratorValue = numeratorBuckets[numeratorKey];
      let unMappedName = numeratorKey;
      series.push({
        name: this.mapLabel(numeratorKey),
        data: [numeratorValue],
        legendLabel: this.mapLabel(numeratorKey),
        color: PALETTE[this.mapColor(numeratorKey)],
        custom: {
          value: this.intl.formatNumber(numeratorValue),
          total: this.intl.formatNumber(numeratorComparator),
        },
        unMappedName,
      });
    });

    if (denominatorTotal) {
      series.push({
        name: this.mapLabel('remaining'),
        data: [denominatorTotal - numeratorTotal],
        legendLabel: this.mapLabel('remaining'),
        color: PALETTE[this.mapColor('remaining')],
        custom: {
          value: this.intl.formatNumber(denominatorTotal - numeratorTotal),
          total: this.intl.formatNumber(denominatorTotal),
        },
        unMappedName: 'remaining',
      });
    }

    return series;
  }

  sortKeys(keys) {
    return [...keys].sort((keyA, keyB) => {
      if (keyA === 'confirmed_resolved') {
        return -1;
      } else if (keyB === 'confirmed_resolved') {
        return 1;
      } else if (!isNaN(keyA) && !isNaN(keyB)) {
        return keyB - keyA;
      } else {
        return 0;
      }
    });
  }

  buildAllSeries(allPoints) {
    let result = {};
    for (let seriesName of Object.keys(allPoints)) {
      let seriesPoints = allPoints[seriesName];
      result[seriesName] = this.buildSeries(seriesName, seriesPoints);
    }
    return result;
  }

  getSeriesData(outerGroup) {
    let allPoints = {};
    let emptyPoints = new Map(this.getAllXValues(outerGroup).map((x) => [x, null]));
    for (let [i, column] of outerGroup.values.entries()) {
      if (this.dataConfig.yAxis) {
        let x = column.value;
        let data = column.groups.flatMap((innerGroup) => this.getValuesFromInnerGroup(innerGroup));
        for (let [seriesName, y] of data) {
          if (!allPoints[seriesName]) {
            allPoints[seriesName] = new Map(emptyPoints);
          }
          let points = allPoints[seriesName];
          points.set(x, y);
        }
      } else {
        let x = column;
        let y = outerGroup.aggregations[0].values[i];
        allPoints[x] = [y];
      }
    }
    return this.buildAllSeries(allPoints);
  }

  getValuesFromInnerGroup(innerGroup) {
    let seriesNames = innerGroup.values;
    let seriesYValues = innerGroup.aggregations[0].values;
    return _.zip(seriesNames, seriesYValues);
  }

  configureOtherSeries(allSeries) {
    let otherSeries = allSeries[this.otherSeriesName];
    if (otherSeries) {
      otherSeries.color = PALETTE['neutral-container'];
      otherSeries.hoverColor = PALETTE['neutral-container-emphasis'];
    }
  }

  seriesIsEmpty(series) {
    return series.data.every((point) => point[1] === null);
  }

  orderSeries(allSeries) {
    let { Other, ...remainingSeries } = allSeries;

    if (this.isLineChart || this.isAreaChart) {
      remainingSeries = Object.values(remainingSeries).sort(
        // Order the series by their max y values
        (a, b) => Math.max(...a.data.map((x) => x[1])) - Math.max(...b.data.map((x) => x[1])),
      );
    }

    if (Other) {
      return [...Object.values(remainingSeries), Other];
    } else {
      return Object.values(remainingSeries);
    }
  }

  convertNullsToZeros(dataWithNulls) {
    return dataWithNulls.map((group) => {
      let data = group.data.map((point) => {
        return [point[0], point[1] || 0, point[2]];
      });
      return { ...group, data };
    });
  }

  mapLabel(label) {
    let legendMappingFunction = this.viewConfig.legendMappingFunction;
    if (legendMappingFunction) {
      return legendMappingFunction(label);
    }
    return label;
  }

  mapColor(value) {
    let colorMappingFunction = this.viewConfig.colorMappingFunction;
    if (colorMappingFunction) {
      return colorMappingFunction(value);
    }
    return value;
  }

  forStackedChartResponse(responses) {
    let allSeries;
    let metric = this.viewConfig.metrics?.[0];
    let aggregation = this.dataConfig.series[0].type;

    if (responses.length === 2) {
      let numeratorData = responses[0]?.groups[0];
      let denominatorData = responses[1]?.groups[0];

      let numeratorSeries = this.getSeriesData(numeratorData);
      let denominatorSeries = this.getSeriesData(denominatorData);

      if (metric?.type === 'ratio') {
        allSeries = calculateSegmentedRatioData({
          numeratorData: numeratorSeries,
          denominatorData: denominatorSeries,
          unit: metric?.unit,
        });
      } else if (metric?.type === 'percentage') {
        allSeries = calculateSegmentedPercentageData({
          numeratorData: numeratorSeries,
          denominatorData: denominatorSeries,
        });
      }
    } else {
      let outerGroup = responses[0]?.groups[0];
      allSeries = this.getSeriesData(outerGroup);
    }

    this.configureOtherSeries(allSeries);
    let orderedSeries = this.orderSeries(allSeries);

    if ((this.isLineChart || this.isAreaChart) && shouldConvertNullsToZeros(aggregation, metric)) {
      return this.convertNullsToZeros(orderedSeries);
    }

    return orderedSeries;
  }

  getHorizontalBarTotal(bucket) {
    let total = 0;
    for (let key of Object.keys(bucket)) {
      total += bucket[key];
    }
    return total;
  }

  getHorizontalBarBuckets(data) {
    let buckets = {};
    for (let i = 0; i < data.values.length; i++) {
      let key = data.values[i];
      let value = data.aggregations[0].values[i];
      buckets[key] = value;
    }

    return buckets;
  }

  forHorizontalBarChartResponse(responses) {
    if (this.viewConfig?.chartType === VISUALIZATION_TYPES.HORIZONTAL_BAR_WITH_COUNTER) {
      return this.buildSeriesForHorizontalBar(responses);
    }

    return this.forStackedChartResponse(responses);
  }
}
