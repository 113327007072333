/* RESPONSIBLE TEAM: team-actions */
// eslint-disable-next-line no-restricted-imports
import containerLookup from 'embercom/lib/container-lookup';

let intl = containerLookup('service:intl');

export const HTTP_METHODS = [
  {
    text: 'GET',
    value: 'get',
    description: intl.t('workflow-connector.builder.body.request.method.get-description'),
  },
  {
    text: 'POST',
    value: 'post',
    description: intl.t('workflow-connector.builder.body.request.method.post-description'),
  },
  {
    text: 'PUT',
    value: 'put',
    description: intl.t('workflow-connector.builder.body.request.method.put-description'),
  },
  {
    text: 'DELETE',
    value: 'delete',
    description: intl.t('workflow-connector.builder.body.request.method.delete-description'),
  },
  {
    text: 'PATCH',
    value: 'patch',
    description: intl.t('workflow-connector.builder.body.request.method.patch-description'),
  },
];

export const FIN_ACTIONS_SUPPORTED_HTTP_METHODS = ['get', 'post'];
