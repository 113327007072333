/* RESPONSIBLE TEAM: team-reporting */
import { capitalize } from '@ember/string';
import { isPresent } from '@ember/utils';
import { getNameFromFilterIdentifier } from 'embercom/lib/reporting/custom/filter-helpers';
import {
  buildFunctionToMapFromDatabaseValueToDisplayName,
  labelMapperFallback,
  formatTableValue,
  formatTimestamp,
} from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import { setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import {
  seriesNameForMetric,
  getTeammates,
  getTeams,
} from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import { requestNameFor } from '../chart-data-resource-compatible-helper';

export default class TableColumnBuilder {
  @service appService;
  @service intl;
  @service reportingMetrics;

  constructor(renderableChart, owner) {
    this.renderableChart = renderableChart;
    setOwner(this, owner);
  }

  buildColumns() {
    let viewByFormatterFn = labelMapperFallback(
      buildFunctionToMapFromDatabaseValueToDisplayName(this.renderableChart.viewBy),
    );
    let viewByAttribute = this.reportingMetrics.getAttributeByField(
      this.renderableChart.viewBy,
      this.renderableChart.metric(0).datasetId,
    );
    if (!viewByAttribute) {
      console.warn(
        `Could not find attribute for viewBy field ${this.renderableChart.metric(0).datasetId}:${this.renderableChart.viewBy}`,
      );
    }
    let groupColumn = this.renderableChart.isBrokenDownByTime
      ? {
          label: capitalize(
            this.renderableChart.viewByTimeInterval || this.renderableChart.dateRange.interval,
          ),
          isMain: true,
          valuePath: 'groupName',
          isSortable: true,
          minWidth: '150px',
          aliases: {
            'row-summary': this.intl.t('reporting.custom-reports.chart.summary'),
          },
          formatter: (value) =>
            formatTimestamp(
              this.renderableChart.viewByTimeInterval,
              value / 1000,
              this.renderableChart.reportState?.timezone,
            ),
        }
      : {
          label: getNameFromFilterIdentifier(this.renderableChart.viewBy, this.renderableChart),
          isMain: true,
          valuePath: 'groupName',
          isSortable: true,
          formatter: viewByFormatterFn,
          sortingHelper: viewByFormatterFn && ((row) => viewByFormatterFn(row['groupName'])),
          aliases: {
            'row-summary': this.intl.t('reporting.custom-reports.chart.summary'),
          },
          canDrillIn:
            viewByAttribute &&
            viewByAttribute.dataType !== 'topic' &&
            viewByAttribute.dataType !== 'ticket_type' &&
            viewByAttribute.dataType !== 'call_type' &&
            this.appService.app.canDrillInFromTableCells &&
            this.renderableChart.visualizationOptions?.allowDrillInFromChart,
          attribute: viewByAttribute,
        };
    if (this.shouldRenderTeammateCellComponent) {
      groupColumn = { ...groupColumn, ...this.teammateCell };
    } else if (this.shouldRenderFinContentCellComponent) {
      groupColumn = { ...groupColumn, ...this.finContentCell };
    }

    let metricColumns = this.renderableChart.segmentBy
      ? this._multiSeriesSegmentedColumns
      : this._multiSeriesColumns;
    return [groupColumn, ...metricColumns];
  }

  get _multiSeriesColumns() {
    return this.renderableChart.chartSeries.toArray().flatMap((series, index) => {
      let metric = series.metric;
      let aggregationName = series.aggregation;
      let valuePath = requestNameFor(index, metric, true);
      let column = {
        label: !this.renderableChart.shouldRenderChrome
          ? series.metricDisplayName
          : seriesNameForMetric(
              metric.name,
              aggregationName,
              this.renderableChart.shouldRenderChrome,
            ),
        valuePath,
        previousValuePath: `${valuePath}_previous`,
        isSortable: true,
        tooltip: metric.description,
        formatter: (value) => formatTableValue(value, metric.unit),
        metricFunction: series.aggregation || metric.defaultAggregation,
        metric,
        ...((this.renderableChart.shouldRenderChrome ||
          !this.renderableChart.reportState?.isStatic ||
          this.renderableChart.reportState?.id === 'csat_v2_report') && {
          hasTooltipBlock: true,
          isTooltipInteractive: true,
          labelCanWrap: true,
          minWidth: this.getMinWidthForColumn(series.aggregation),
        }),
        series,
      };

      if (metric.type === 'ratio') {
        column.getRowValue = (row) => this.formatRatio(row, valuePath);
        column.getPreviousRowValue = (row) => this.formatPreviousPeriodRatio(row, valuePath);
      }
      let metricUnitRatioPercentageMetrics = this.getMetricUnitRatioPercentageMetrics(metric);
      if (isPresent(metricUnitRatioPercentageMetrics)) {
        column.numeratorFormatter = (value) =>
          formatTableValue(value, metricUnitRatioPercentageMetrics.numeratorMetricUnit);
        column.denominatorFormatter = (value) =>
          formatTableValue(value, metricUnitRatioPercentageMetrics.denominatorMetricUnit);
      }
      return column;
    });
  }

  getMetricUnitRatioPercentageMetrics(metric) {
    if (!isPresent(metric) || !['ratio', 'percentage'].includes(metric?.type)) {
      return undefined;
    }
    let numeratorMetric = this.reportingMetrics.getMetricById(metric.numeratorMetricId);
    let denominatorMetric = this.reportingMetrics.getMetricById(metric.denominatorMetricId);
    return {
      numeratorMetricUnit: numeratorMetric?.unit,
      denominatorMetricUnit: denominatorMetric?.unit,
    };
  }

  getMinWidthForColumn(aggregation) {
    return isPresent(aggregation) ? '205px' : '175px';
  }

  get _multiSeriesSegmentedColumns() {
    return this.renderableChart.chartSeries.toArray().flatMap((series, index) => {
      let metric = series.metric;
      let valuePath = requestNameFor(index, metric, true);
      let column = {
        valuePath,
        isSortable: true,
        tooltip: metric.description,
        metricFunction: series.aggregation || metric.defaultAggregation,
        labelFormatter: this.labelFormatter,
        formatter: (value) => formatTableValue(value, metric.unit),
        metricId: metric.id,
        metric,
      };
      if (metric.type === 'percentage') {
        let numeratorValuePath = requestNameFor(index, metric.numerator, true);
        let denominatorValuePath = requestNameFor(index, metric.denominator, true);
        column.getRowValue = (row, col) =>
          this.formatPercentage(row, valuePath, numeratorValuePath, denominatorValuePath, col);
      }
      if (metric.type === 'ratio') {
        column.getRowValue = (row, col) => this.formatRatio(row, valuePath, col);
      }
      let metricUnitRatioPercentageMetrics = this.getMetricUnitRatioPercentageMetrics(metric);
      if (isPresent(metricUnitRatioPercentageMetrics)) {
        column.numeratorFormatter = (value) =>
          formatTableValue(value, metricUnitRatioPercentageMetrics.numeratorMetricUnit);
        column.denominatorFormatter = (value) =>
          formatTableValue(value, metricUnitRatioPercentageMetrics.denominatorMetricUnit);
      }
      return column;
    });
  }

  get shouldRenderTeammateCellComponent() {
    return (
      this.renderableChart.shouldRenderChrome &&
      (this.renderableChart.viewBy === 'teammate' || this.renderableChart.viewBy === 'team')
    );
  }

  get teammateCell() {
    return {
      component: 'reporting/flexible/table-teammate-cell',
      componentData: this.renderableChart.viewBy === 'team' ? getTeams() : getTeammates(),
      aliases: {
        'row-summary':
          this.renderableChart.viewBy === 'team'
            ? this.intl.t('reporting.team-inbox-performance.summary-row')
            : this.intl.t('reporting.teammate-performance.summary-row'),
      },
    };
  }

  get shouldRenderFinContentCellComponent() {
    return (
      this.renderableChart.visualizationType === 'bespoke' &&
      (this.renderableChart.viewBy === 'fin.content_references' ||
        this.renderableChart.viewBy === 'content_references')
    );
  }

  get finContentCell() {
    return {
      component: 'reporting/flexible/knowledge-hub/title-cell',
      label: this.intl.t('components.reporting.bespoke.fin-content-table.columns.title'),
      isSortable: false,
    };
  }

  calculatePercentage(row, numeratorValuePath, denominatorValuePath) {
    let numerator = row[numeratorValuePath] || 0;
    let denominator = row[denominatorValuePath];

    if (!denominator) {
      return '-';
    }

    let percentage = this.intl.formatNumber(numerator / denominator, {
      style: 'percent',
      maximumFractionDigits: 2,
    });

    return this.intl.t('reporting.percentage-with-numbers', {
      percentage,
      numerator,
      denominator,
    });
  }

  formatPercentage(row, valuePath, numeratorValuePath, denominatorValuePath, column = null) {
    if (column) {
      let rowPath = row?.[column.valuePath];
      let value = rowPath?.[valuePath];
      return column.formatter(value);
    } else {
      return this.calculatePercentage(row, numeratorValuePath, denominatorValuePath);
    }
  }

  formatRatio(row, valuePath, column = null) {
    let value;
    if (column) {
      let rowPath = row?.[column.valuePath];
      value = rowPath?.[valuePath];
    } else {
      value = row[valuePath];
    }

    if (isPresent(value)) {
      return this.intl.formatNumber(value, {
        maximumFractionDigits: 1,
      });
    }
    return '-';
  }

  formatPreviousPeriodRatio(row, valuePath) {
    let rowId = `${valuePath}_previous`;
    let value = row[rowId];

    return this.intl.formatNumber(value, {
      maximumFractionDigits: 1,
    });
  }

  get labelFormatter() {
    return this.renderableChart.isSegmentedByTime
      ? (value) =>
          formatTimestamp(
            this.renderableChart.segmentByTimeInterval || this.renderableChart.dateRange.interval,
            value / 1000,
            this.renderableChart.reportState?.timezone,
          )
      : labelMapperFallback(
          buildFunctionToMapFromDatabaseValueToDisplayName(this.renderableChart.segmentBy),
        );
  }
}
