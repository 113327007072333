/* RESPONSIBLE TEAM: team-actions */
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { get } from 'embercom/lib/ajax';
import { isPresent } from '@ember/utils';

type MigrationBackendStatus =
  | 'preparing'
  | 'fanout_in_progress'
  | 'processing'
  | 'stopping'
  | 'completed'
  | 'completed_with_errors'
  | 'cancelled';

export type MigrationStatusEnum = MigrationBackendStatus | 'loading' | 'draft' | 'none';

export class ImportZendeskContext {
  isConnected: boolean;
  isInstalled: boolean;
  isAccountCreated: boolean;
  subdomain: string;
  hasStartedMigration: boolean;
  migrationStatus: MigrationStatusEnum;

  constructor(
    isConnected: boolean,
    isInstalled: boolean,
    isAccountCreated: boolean,
    subdomain: string,
    hasStartedMigration: boolean,
    migrationStatus: MigrationStatusEnum,
  ) {
    this.isConnected = isConnected;
    this.isInstalled = isInstalled;
    this.isAccountCreated = isAccountCreated;
    this.subdomain = subdomain;
    this.hasStartedMigration = hasStartedMigration;
    this.migrationStatus = migrationStatus;
  }
}

export class ZendeskContextLoader {
  isAppPackageInstalled = false;
  isTokenValid = false;
  isAccountCreated = false;
  subdomain = '';
  hasStartedMigration = false;
  migrationStatus: MigrationStatusEnum = 'none';

  workspaceId: string;
  integrationCode = 'migrate-from-zendesk';

  constructor(workspaceId: string) {
    this.workspaceId = workspaceId;
  }

  async load() {
    await taskFor(this.loadAppPackage).perform();
    if (this.isAppPackageInstalled) {
      await taskFor(this.loadIntegrationStatus).perform();
      await taskFor(this.loadDataImportStatus).perform();
    }
  }

  get result() {
    return new ImportZendeskContext(
      this.isInstalled && this.isTokenValid,
      this.isInstalled,
      this.isAccountCreated,
      this.subdomain,
      this.hasStartedMigration,
      this.migrationStatus,
    );
  }

  get isInstalled() {
    return this.isAppPackageInstalled && this.isAccountCreated;
  }

  get isConnected() {
    return this.isInstalled && this.isTokenValid;
  }

  @dropTask *loadAppPackage(): TaskGenerator<void> {
    try {
      let response = yield get(
        `/ember/appstore/app_packages/migrate-from-zendesk?app_id=${this.workspaceId}`,
      );
      this.isAppPackageInstalled = response.is_installed as boolean;
    } catch (e) {
      this.isAppPackageInstalled = false;
    }
  }

  @dropTask *loadIntegrationStatus(): TaskGenerator<void> {
    try {
      let response = yield get(
        `/ember/migrate_from_zendesk_integrations/integration_status?app_id=${this.workspaceId}`,
      );
      this.isAccountCreated = true;
      this.isTokenValid = response.token_valid as boolean;
      this.subdomain = response.subdomain as string;
    } catch (e) {
      if (e.jqXHR?.status === 404) {
        this.isAccountCreated = false;
      }
    }
  }

  @dropTask *loadDataImportStatus(): TaskGenerator<void> {
    try {
      let response = yield get(
        `/ember/migrate_from_crm_integrations/data_import_status?app_id=${this.workspaceId}&integration_code=${this.integrationCode}`,
      );
      this.hasStartedMigration = isPresent(response.data_import);
      if (this.hasStartedMigration) {
        this.migrationStatus = response.data_import.state;
      }
    } catch (e) {
      if (e.jqXHR?.status === 404) {
        this.hasStartedMigration = false;
      }
    }
  }
}
