/* RESPONSIBLE TEAM: team-proactive-support */
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export const CAN_SEND_MESSAGES = 'can_send_messages';
export const CAN_MANAGE_PRODUCT_TOURS = 'can_manage_product_tours';

export const EMAIL_LOW_ENGAGEMENT_THRESHOLDS = {
  SPAM_RATE: 0.0008,
  UNSUBSCRIBE_RATE: 0.04,
  BOUNCE_RATE: 0.04,
  OPEN_RATE: 0.1,
};

export const LEARN_MORE_CONTENT_IDS = {
  getStarted: 3292835,
  series: 4425207,
  survey: 23574474,
  checklist: 28453901,
  tour: 437066,
  post: 3292781,
  chat: 3292781,
  banner: 4557393,
  email: 3292845,
  push: 3292847,
  carousel: 4164164,
  tooltip_group: 6475940,
  sms: 6311176,
  newsItem: 6362251,
  newsfeed: 6362251,
  custom_bot: 2216447,
  all: 3292835,
  productTour: 2309,
  whatsapp: 8259544,
};

export const PREVIEW_DEVICE_OPTIONS = [
  { device: 'web', icon: 'computer' },
  { device: 'mobile', icon: 'mobile' },
];

export const IN_OPERATOR = 'IN';

export const NIN_OPERATOR = 'NIN';

export const LEGACY_TYPES = {
  [objectTypes.email]: objectTypes.legacyMessageEmail,
  [objectTypes.push]: objectTypes.legacyMessagePush,
  [objectTypes.chat]: objectTypes.legacyMessageInApp,
};

export const EXTRA_LEGACY_TYPES = Object.keys(LEGACY_TYPES).map((type: any) => {
  return LEGACY_TYPES[type];
});

export const OUTBOUND_COLUMN_DATA: Record<
  string,
  {
    valuePath: string;
    type?: string;
    labelIntlKey?: string;
    isDisabled?: boolean;
    isSortable?: boolean;
    minWidth?: string;
  }
> = {
  checkBox: { valuePath: 'checkBox', type: 'checkbox', isDisabled: true },
  title: { valuePath: 'title', labelIntlKey: 'outbound.table.columns.title', isDisabled: true },
  version: {
    valuePath: 'version',
    labelIntlKey: 'outbound.table.columns.version',
    minWidth: '130px',
  },
  state: { valuePath: 'state', labelIntlKey: 'outbound.table.columns.state' },
  subscriptionType: {
    valuePath: 'subscriptionType',
    labelIntlKey: 'outbound.table.columns.subscription-type',
    minWidth: '220px',
  },
  sender: { valuePath: 'sender', labelIntlKey: 'outbound.table.columns.sender' },
  insertable: {
    valuePath: 'insertable',
    labelIntlKey: 'outbound.table.columns.insertable',
    minWidth: '204px',
  },
  contentTypes: {
    valuePath: 'contentTypes',
    labelIntlKey: 'outbound.table.columns.content-type',
    minWidth: '180px',
  },
  sentCount: {
    valuePath: 'sentCount',
    labelIntlKey: 'outbound.table.columns.sent-count',
  },
  goalPercent: {
    valuePath: 'goalPercent',
    labelIntlKey: 'outbound.table.columns.goal-percent',
  },
  openPercent: {
    valuePath: 'openPercent',
    labelIntlKey: 'outbound.table.columns.open-percent',
  },
  clickPercent: {
    valuePath: 'clickPercent',
    labelIntlKey: 'outbound.table.columns.click-percent',
  },
  replyPercent: {
    valuePath: 'replyPercent',
    labelIntlKey: 'outbound.table.columns.reply-percent',
  },
  failedPercent: {
    valuePath: 'failedPercent',
    labelIntlKey: 'outbound.table.columns.failed-percent',
  },
  engagedPercent: {
    valuePath: 'engagedPercent',
    labelIntlKey: 'outbound.table.columns.engage-percent',
  },
  completedPercent: {
    valuePath: 'completedPercent',
    labelIntlKey: 'outbound.table.columns.completed-percent',
  },
  tourFailuresPercentage: {
    valuePath: 'tourFailuresPercentage',
    labelIntlKey: 'outbound.table.columns.issues',
  },
  respondedPercent: {
    valuePath: 'respondedPercent',
    labelIntlKey: 'outbound.table.columns.respond-percent',
  },
  keywordReplies: {
    valuePath: 'keywordReplies',
    labelIntlKey: 'outbound.table.columns.keyword-replies',
  },
  reactionPercent: {
    valuePath: 'reactionPercent',
    labelIntlKey: 'outbound.table.columns.reaction-percent',
  },
  audienceTypes: {
    valuePath: 'audienceTypes',
    labelIntlKey: 'outbound.table.columns.audience-types',
  },
  createdAt: {
    valuePath: 'createdAt',
    labelIntlKey: 'outbound.table.columns.created-at',
    minWidth: '144px',
    isSortable: true,
  },
  firstWentLiveAt: {
    valuePath: 'firstWentLiveAt',
    labelIntlKey: 'outbound.table.columns.first-went-live-at',
    minWidth: '120px',
    isSortable: true,
  },
};

type OutboundColumnKey = keyof typeof OUTBOUND_COLUMN_DATA;

function getOutboundColumnKeys() {
  let keys: Record<OutboundColumnKey, string> = {};
  let columnKeys = Object.keys(OUTBOUND_COLUMN_DATA);
  columnKeys.forEach((key) => {
    keys[key] = OUTBOUND_COLUMN_DATA[key]['valuePath'];
  });
  return keys;
}

export const OUTBOUND_COLUMN_KEYS = getOutboundColumnKeys();

export const DEFAULT_COLUMN_LIST = [
  OUTBOUND_COLUMN_KEYS.state,
  OUTBOUND_COLUMN_KEYS.sender,
  OUTBOUND_COLUMN_KEYS.contentTypes,
  OUTBOUND_COLUMN_KEYS.sentCount,
  OUTBOUND_COLUMN_KEYS.goalPercent,
  OUTBOUND_COLUMN_KEYS.audienceTypes,
  OUTBOUND_COLUMN_KEYS.createdAt,
  OUTBOUND_COLUMN_KEYS.firstWentLiveAt,
];

export const SELECTABLE_COLUMNS = Object.keys(OUTBOUND_COLUMN_DATA)
  .filter((key) => {
    if (key === OUTBOUND_COLUMN_KEYS.checkBox || key === OUTBOUND_COLUMN_KEYS.title) {
      return false;
    }

    return true;
  })
  .map((key) => {
    return {
      value: OUTBOUND_COLUMN_DATA[key]['valuePath'],
      labelIntlKey: OUTBOUND_COLUMN_DATA[key]['labelIntlKey'],
    };
  });
