/* RESPONSIBLE TEAM: team-channels */
import type UserSummary from 'embercom/objects/inbox/user-summary';
import Conversation, { type NewConversation } from 'embercom/objects/inbox/conversation';
import type UserComment from 'embercom/objects/inbox/renderable/user-comment';
import type AdminComment from 'embercom/objects/inbox/renderable/admin-comment';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import { Channel } from 'embercom/models/data/inbox/channels';

class DefaultRecipients {
  private conversation: Conversation | NewConversation;
  protected readonly conversationParticipants: UserSummary[];
  protected _toRecipients?: UserSummary[];
  protected _ccRecipients?: UserSummary[];

  constructor(conversation: Conversation | NewConversation) {
    this.conversation = conversation;
    this.conversationParticipants = this.conversation.participantSummaries;
  }

  get originalParticipants() {
    return this.conversationParticipants;
  }

  get toRecipients() {
    return this.originalParticipants;
  }

  get ccRecipients(): UserSummary[] {
    return [];
  }
}

class EmailReplyRecipients extends DefaultRecipients {
  get toRecipients(): UserSummary[] {
    if (!this._toRecipients) {
      this._toRecipients = [this.conversationParticipants[0]];
    }
    return this._toRecipients;
  }

  get ccRecipients(): UserSummary[] {
    if (!this._ccRecipients) {
      let toRecipientEmails = this.toRecipients.map((recipient) => recipient.email);
      this._ccRecipients = this.conversationParticipants.filter(
        (participant) => !toRecipientEmails.includes(participant.email),
      );
    }
    return this._ccRecipients;
  }
}

class UserCommentReplyRecipients extends DefaultRecipients {
  private lastPart: UserComment;

  constructor(conversation: Conversation, lastPart: UserComment) {
    super(conversation);
    this.lastPart = lastPart;
  }

  get toRecipients(): UserSummary[] {
    if (!this._toRecipients) {
      this._toRecipients = [this.lastPart.userSummary];
    }
    return this._toRecipients;
  }

  get ccRecipients(): UserSummary[] {
    if (!this._ccRecipients) {
      let toRecipientEmails = this.toRecipients.map((recipient) => recipient.email);
      this._ccRecipients = this.conversationParticipants.filter(
        (participant) => !toRecipientEmails.includes(participant.email),
      );
    }
    return this._ccRecipients;
  }
}

class AdminCommentReplyRecipients extends DefaultRecipients {
  private lastPart: AdminComment;

  constructor(conversation: Conversation, lastPart: AdminComment) {
    super(conversation);
    this.lastPart = lastPart;
  }

  get toRecipients(): UserSummary[] {
    if (!this._toRecipients) {
      let toAddresses = this.lastPart.emailMetadata?.headerAddresses?.to;
      let toRecipientEmails = toAddresses?.map((address) => address.email);

      this._toRecipients = this.conversationParticipants.filter((participant) =>
        toRecipientEmails?.includes(participant.email),
      );
    }
    return this._toRecipients;
  }

  get ccRecipients(): UserSummary[] {
    if (!this._ccRecipients) {
      let ccAddresses = this.lastPart.emailMetadata?.headerAddresses?.cc;
      let ccRecipientEmails = ccAddresses?.map((address) => address.email);

      this._ccRecipients = this.conversationParticipants.filter((participant) =>
        ccRecipientEmails?.includes(participant.email),
      );
    }
    return this._ccRecipients;
  }
}

function partIsUserComment(part: RenderablePart | undefined) {
  return (
    part?.renderableType === RenderableType.UserComment ||
    part?.renderableType === RenderableType.UserEmailComment
  );
}

function isUserCommentFromAParticipant(part: RenderablePart, conversation: Conversation) {
  let userComment = part.renderableData as UserComment;
  let participantEmails = conversation.participantSummaries.map((participant) => participant.email);

  return partIsUserComment(part) && participantEmails.includes(userComment.userSummary.email);
}

function isAdminCommentWithEmailMetadata(part: RenderablePart) {
  return part.renderableType === RenderableType.AdminComment && part.renderableData.emailMetadata;
}

function lastValidPartForReply(conversation: Conversation) {
  let allHumanPartsOrderedLastToFirst = conversation.allHumanCommentParts.slice().reverse();

  return allHumanPartsOrderedLastToFirst.find((part) => {
    return (
      isAdminCommentWithEmailMetadata(part) || isUserCommentFromAParticipant(part, conversation)
    );
  });
}

function isEmailCurrentChannel(conversation: Conversation | NewConversation) {
  return conversation instanceof Conversation && conversation?.channel.current === Channel.Email;
}

function partIsAdminComment(part: RenderablePart | undefined) {
  return part?.renderableType === RenderableType.AdminComment;
}

function createEmailReplyRecipients(conversation: Conversation) {
  let lastPart = lastValidPartForReply(conversation);

  if (partIsUserComment(lastPart)) {
    return new UserCommentReplyRecipients(conversation, lastPart?.renderableData as UserComment);
  } else if (partIsAdminComment(lastPart)) {
    return new AdminCommentReplyRecipients(conversation, lastPart?.renderableData as AdminComment);
  } else {
    return new EmailReplyRecipients(conversation);
  }
}

export default function createReplyRecipients(conversation: Conversation | NewConversation) {
  if (isEmailCurrentChannel(conversation)) {
    return createEmailReplyRecipients(conversation as Conversation);
  } else {
    return new DefaultRecipients(conversation);
  }
}
