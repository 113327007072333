/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
import Controller from '@ember/controller';
import { gt, alias, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { matchBehaviors } from 'embercom/models/data/matching-system/matching-constants';
import { PAGE_SIZE, statisticKeys } from 'embercom/models/data/outbound/constants';
import {
  objectNames,
  objectTypes,
  humanReadableObjectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import { action, computed, set } from '@ember/object';
import { task } from 'ember-concurrency';
import { isPresent } from '@ember/utils';
import { OUTBOUND_BOT_TYPE, EVERYONE_PREDICATE } from 'embercom/lib/operator/custom-bots/constants';
import { defaultSurveyPredicates } from 'embercom/models/data/survey/constants';
import { defaultEmailPredicates } from 'embercom/models/data/email/constants';
import { IN_OPERATOR, EXTRA_LEGACY_TYPES } from 'embercom/lib/outbound/constants';
import { inject as controller } from '@ember/controller';

const PAGE_FROM_DEFAULT = 0;

export default Controller.extend({
  outboundController: controller('apps.app.outbound'),
  store: service(),
  appService: service(),
  app: readOnly('appService.app'),
  outboundHomeService: service(),
  tagService: service(),
  intercomEventService: service(),
  permissionsService: service(),
  notificationsService: service(),
  hasItemsToDisplay: gt('model.length', 0),
  perPage: PAGE_SIZE,
  pageFrom: PAGE_FROM_DEFAULT,
  isLoading: false,
  statisticKeys,
  selectedContentWrappers: computed('model.@each.isSelected', function () {
    return this.model.filter((contentWrapper) => contentWrapper.get('isSelected'));
  }),
  hasSelectedContentWrappers: gt('selectedContentWrappers.length', 0),

  queryParams: [
    'tagIds',
    'tagOperator',
    'senderId',
    'state',
    'audiences',
    'subscriptionTypeId',
    'startTour',
    'searchString',
    'selectedObjectTypes',
    'hideSeries',
  ],
  tagIds: null,
  tagOperator: IN_OPERATOR,
  senderId: null,
  state: null,
  audiences: null,
  sortBy: null,
  sortDirection: null,
  subscriptionTypeId: null,
  extraSearchParams: {},
  customRulesetCreationParams: {},
  startTour: null,
  searchString: null,
  selectedObjectTypes: null,
  hideSeries: null,
  activeFilter: null,
  setActiveFilter: action(async function (filterType) {
    this.set('activeFilter', filterType);
  }),
  // Available filters
  selectedSenderValue: alias('outboundHomeService.selectedSenderValue'),
  selectedAudienceValues: alias('outboundHomeService.selectedAudienceValues'),
  selectedStateValue: alias('outboundHomeService.selectedStateValue'),
  selectedTagOperator: alias('outboundHomeService.selectedTagOperator'),
  selectedTagValues: alias('outboundHomeService.selectedTagValues'),
  selectedSubscriptionTypeValue: alias('outboundHomeService.selectedSubscriptionTypeValue'),
  selectedMatchBehaviorValues: alias('outboundHomeService.selectedMatchBehaviorValues'),
  additionalSearchableData: alias('outboundHomeService.additionalSearchableData'),
  searchStringValue: alias('outboundHomeService.searchString'),
  selectedObjectTypeValues: alias('outboundHomeService.selectedObjectTypes'),
  lastSelectedContentType: alias('outboundHomeService.lastSelectedContentType'),
  predicates: alias('outboundHomeService.predicates'),
  hideSeriesValue: alias('outboundHomeService.hideSeries'),

  hasAppliedFilter: computed(
    'selectedAudienceValues',
    'selectedSenderValue',
    'selectedStateValue',
    'selectedTagOperator',
    'selectedTagValues',
    'selectedSubscriptionTypeValue',
    'selectedMatchBehaviorValues',
    'selectedObjectTypeValues',
    'additionalSearchableData',
    'searchString',
    'predicates',
    'hideSeries',
    function () {
      let result =
        this.selectedMatchBehaviorValues !== null ||
        this.selectedAudienceValues?.length === 1 ||
        this.selectedAudienceValues?.length === 2 ||
        this.selectedSenderValue !== null ||
        this.selectedStateValue !== null ||
        isPresent(this.selectedTagValues) ||
        this.selectedTagOperator !== IN_OPERATOR ||
        this.selectedSubscriptionTypeValue !== null ||
        (isPresent(this.selectedObjectTypeValues) &&
          this.outboundHomeService.contentToDisplay.length + EXTRA_LEGACY_TYPES.length !==
            this.selectedObjectTypeValues?.length) ||
        this.additionalSearchableData !== null ||
        this.searchString !== null ||
        this.predicates?.length > 0 ||
        this.hideSeries !== null;
      return result;
    },
  ),

  // To be overridden by subclasses
  objectTypes: null,
  pageTitle: '',
  selectedObjectType: computed('bannerData.selectedObjectType', function () {
    return humanReadableObjectNames[this.bannerData.selectedObjectType] || 'message';
  }),

  init(...args) {
    this._super(...args);
    this.originalPerPage = this.perPage;
    this.originalPageFrom = this.pageFrom;
  },

  _resetPagingSettings() {
    this.setProperties({
      perPage: this.originalPerPage,
      pageFrom: this.originalPageFrom,
    });
  },

  _filterForTrackEvent(filterProperty) {
    switch (filterProperty) {
      case 'selectedAudienceValues':
        return 'audiences';
      case 'selectedSenderValue':
        return 'sender';
      case 'selectedStateValue':
        return 'state';
      case 'selectedTagValues':
        return 'tags';
      case 'selectedTagOperator':
        return 'tagOperator';
      case 'selectedSubscriptionTypeValue':
        return 'subscriptionType';
      case 'predicates':
        return 'predicates';
      case 'hideSeries':
        return 'hideSeries';
    }
    return filterProperty;
  },

  _valueForTrackEvent(filterProperty, value) {
    if (filterProperty === 'selectedObjectTypes') {
      if (!isPresent(value)) {
        return ['all'];
      }
    }
    return value;
  },

  fetchMessagesTags: task(function* () {
    try {
      let messagesTags = yield this.tagService.getMessagesTags(this.app);
      this.set('messagesTags', messagesTags);
      this._contentSearch();
    } catch (e) {
      this.notificationsService.notifyError(
        `Something went wrong and we couldn't initialize tags. Please try again.`,
      );
    }
  }).drop(),

  getObjectTypes() {
    let objectTypes = this.objectTypes;
    if (isPresent(this.selectedObjectTypes) && !Array.isArray(this.selectedObjectTypes)) {
      objectTypes = this.selectedObjectTypes.split(',').map((contentType) => Number(contentType));
    }
    return objectTypes;
  },
  async _contentSearch() {
    return await this.outboundHomeService.contentSearchWithFilters({
      object_types: this.getObjectTypes(),
      app_id: this.app.id,
      per_page: this.perPage,
      page_from: this.pageFrom,
      sort_by: this.sortBy,
      sort_direction: this.sortDirection,
      statistics: this.statistics,
      ...this.extraSearchParams,
    });
  },

  _setPropertiesFromResponse(response) {
    this.setProperties({
      model: response.contentWrappers,
      pageFrom: response.pageFrom,
      perPage: response.perPage,
      totalCount: response.totalCount,
      totalPages: response.totalPages,
      lastPageHit: response.lastPageHit,
    });
  },

  _setQueryParams() {
    if (isPresent(this.outboundHomeService.selectedAudienceValues)) {
      this.set('audiences', this.outboundHomeService.selectedAudienceValues);
    } else {
      this.set('audiences', null);
    }
    this.set('senderId', this.outboundHomeService.selectedSenderValue);
    this.set('state', this.outboundHomeService.selectedStateValue);
    if (isPresent(this.outboundHomeService.selectedTagValues)) {
      this.set('tagIds', this.outboundHomeService.selectedTagValues);
    } else {
      this.set('tagIds', null);
    }
    if (isPresent(this.outboundHomeService.selectedTagOperator)) {
      this.set('tagOperator', this.outboundHomeService.selectedTagOperator);
    } else {
      this.set('tagOperator', IN_OPERATOR);
    }
    this.set('subscriptionTypeId', this.outboundHomeService.selectedSubscriptionTypeValue);
    if (isPresent(this.outboundHomeService.additionalSearchableData)) {
      this.set('additionalSearchableData', this.outboundHomeService.additionalSearchableData);
    } else {
      this.set('additionalSearchableData', null);
    }
    if (isPresent(this.outboundHomeService.searchString)) {
      this.set('searchString', this.outboundHomeService.searchString);
    } else {
      this.set('searchString', null);
    }
    if (isPresent(this.outboundHomeService.predicates)) {
      this.set('predicates', this.outboundHomeService.predicates);
    } else {
      this.set('predicates', null);
    }
    if (!isPresent(this.outboundHomeService.selectedObjectTypes)) {
      this.set('selectedObjectTypes', null);
    }
    if (isPresent(this.outboundHomeService.hideSeries)) {
      this.set('hideSeries', this.outboundHomeService.hideSeries);
    } else {
      this.set('hideSeries', null);
    }
  },

  _createRulesetContent(objectType = null, templateId = null, options = null) {
    let rulesetParams = {
      object_type: objectType,
      app_id: this.appService.app.id,
    };

    if (objectType === objectTypes.banner) {
      rulesetParams.match_behavior = matchBehaviors.multi;
    } else if (objectType === objectTypes.tooltipGroup) {
      rulesetParams.match_behavior = matchBehaviors.transient;
    } else if (objectType === objectTypes.tour) {
      rulesetParams.match_behavior = matchBehaviors.single;
      rulesetParams.object_data = {
        from_id: this.app.currentAdmin.id,
        template_id: templateId,
      };
    } else if (objectType === objectTypes.newsItem) {
      rulesetParams = {
        ...rulesetParams,
        admin_id: this.app.currentAdmin.id,
        match_behavior: matchBehaviors.transient,
        role_predicate_group: { predicates: [EVERYONE_PREDICATE] },
      };
    } else if (
      !this.outboundHomeService.selectedMatchBehaviorValues ||
      this.outboundHomeService.selectedTabIsOngoing
    ) {
      rulesetParams.match_behavior = matchBehaviors.single;
    } else if (this.outboundHomeService.selectedTabIsOneOff) {
      rulesetParams.match_behavior = matchBehaviors.static;
    }
    if (objectType === objectTypes.survey && !templateId) {
      rulesetParams.predicate_group = defaultSurveyPredicates;
    }
    if (objectType === objectTypes.email && !templateId) {
      rulesetParams.predicate_group = defaultEmailPredicates;
    }

    if (templateId) {
      rulesetParams.template_id = templateId;
    } else if (objectType === objectTypes.customBot) {
      rulesetParams.object_data = { type: OUTBOUND_BOT_TYPE };
    } else if (objectType === objectTypes.newsItem) {
      rulesetParams.object_data = {
        sender_id: this.app.currentAdmin.id,
      };
    } else {
      rulesetParams.object_data = {};
    }

    if (objectType === objectTypes.email && options) {
      rulesetParams.object_data = {
        ...rulesetParams.object_data,
        email_template_id: options.emailTemplateId,
        email_template_type: options.emailTemplateType,
      };
    }

    Object.assign(rulesetParams, this.customRulesetCreationParams);
    return this.transitionToRoute('apps.app.content.new', rulesetParams);
  },

  _toggleSortDirection() {
    if (this.sortDirection === 'desc') {
      return this.set('sortDirection', 'asc');
    }

    this.set('sortDirection', 'desc');
  },

  createContent: action(function (
    objectType = null,
    deliveryChannel = null,
    audienceType = null,
    templateId = null,
    options = null,
  ) {
    this.set('showContentCreationModal', false);
    this.intercomEventService.trackEvent('create-new-message', {
      messageType: objectType,
      audienceType,
      deliveryChannel,
      templateId,
    });

    if (objectType === objectTypes.series) {
      this.createNewSeries({ templateId });
    } else {
      this._createRulesetContent(objectType, templateId, options);
    }
  }),
  _trackFilterContent(filterProperty, value) {
    let event = {
      list: this.get('target.currentRoute.localName'),
      filter: this._filterForTrackEvent(filterProperty),
      messageType: this.matchBehavior ? 'auto' : 'direct',
      value: this._valueForTrackEvent(filterProperty, value),
      unifiedView: 'true',
    };

    this.intercomEventService.trackEvent('outbound-filtering', event);

    if (filterProperty === 'selectedObjectTypes' && value?.length === 1) {
      if (objectNames[objectTypes.survey] === value[0]) {
        this.intercomEventService.trackEvent('surveys-home-viewed');
      }
      if (objectNames[objectTypes.sms] === value[0]) {
        this.intercomEventService.trackEvent('sms-home-viewed');
      }
    }
  },

  /**
   * Sends a request to search outbound content by a set of filters.
   *
   * @param filterProperty [String] the filter's value to set (see available filters).
   * @param value [String] the value of the filter.
   **/
  filterContent: action(async function (filterProperty, value) {
    this.set('isLoading', true);
    this.outboundHomeService.safelySet(filterProperty, value);
    this.set(filterProperty, value);
    this._resetPagingSettings();
    this._trackFilterContent(filterProperty, value);
    let response = await this._contentSearch();

    this._setPropertiesFromResponse(response);
    this.set('isLoading', false);
    this._setQueryParams();
  }),

  loadMore: action(async function (perPage, pageFrom) {
    this.setProperties({ perPage, pageFrom });
    let response = await this._contentSearch();
    this.setProperties({
      model: [...this.model, ...response.contentWrappers],
      totalCount: response.totalCount,
      totalPages: response.totalPages,
      lastPageHit: response.lastPageHit,
    });
  }),

  reorder: action(async function (updatedPriorities, draggedWrapper) {
    // Here, we make sure that any 'dirty' content wrappers are saved to the store, with the correct
    // priority value to ensure the list is ordered correctly, without making any extra HTTP requests.
    // This will overwrite the updates we make for the UI view above, and will update to the correct
    // value.
    //
    // We asynchronously sync the content wrapper priorities into Elasticsearch, initiated from the
    // ruleset reordering, so that when we search for content wrappers by priority they will return in
    // the correct order. This takes time, however. Reordering one content wrapper updates the priority
    // of many wrappers (those between where the moved one was and now is) (this can also update content
    // wrappers that are not currently visible in the UI).
    //
    // So, we can no longer guarantee searching for content wrappers in priority order immediately after
    // the reorder call will return content wrappers in the correct order, as we cannot guarantee the
    // priorities are synced to Elasticsearch before the search for content wrappers happens. However, we
    // need to ensure that the content wrappers in the store are not dirty and have the correct priorities.
    //
    // The ruleset.reorder call returns the priorities for each content wrapper from MySQL (i.e. the
    // source of truth), so we use that to ensure the content wrappers in the store have the correct
    // priorities set. We do this without making any extra HTTP requests, by using the store's
    // `pushPayload` method.
    //
    // The flow is:
    //   1. Drag and drop a content wrapper into a new position,
    //   2. Set a new 'priority' on each content wrapper based on the updatedPriorities array (ensuring
    //      the correct relative order of the content wrappers),
    //   3. Make the ruleset.reorder request with the previous and next content wrapper positions
    //      based on the updatedPriorities array,
    //   4. Update any content wrapper priorities in the store with the response from ruleset.reorder,
    //   5. Push all dirty content wrappers into the store to 'save' them, and ensure the priority is
    //      correct according to the response from ruleset.reorder.
    //
    // The tradeoff with not making an extra request is that any content wrappers that have been
    // created/updated/deleted since the last page load will not have their information updated in the
    // store (only the priority is being updated), and so these updates will not be displayed on the page
    // for the teammate. This is acceptable, as the teammate will now no longer have content wrappers
    // jumping around on the page after they are reordered. As soon as the user navigates away from the
    // page and back the new content wrappers will appear.

    try {
      // Set the priority of each content wrapper to a value that will ensure the correct relative
      // order of the content wrappers, so that the UI view is rendered correctly (i.e. sorting by
      // priority will give the correct order and so the rows in the UI don't jump around).
      // These priorities may not be the correct **value**, though.
      // The correct priority value will be set once the `ruleset.reorder` method call happens.
      this.model.forEach((wrapper) => wrapper.set('priority', updatedPriorities.indexOf(wrapper)));

      let ruleset = await this.store.findRecord(
        'matching-system/ruleset',
        draggedWrapper.contentWrapperId,
      );

      let draggedWrapperIndex = updatedPriorities.indexOf(draggedWrapper);
      let previousWrapper = updatedPriorities[draggedWrapperIndex - 1];
      let nextWrapper = updatedPriorities[draggedWrapperIndex + 1];

      let response = await ruleset.reorder(previousWrapper, nextWrapper);

      // Map ruleset id to priority.
      let rulesetPriorityMapping = response.reduce((m, rulesetPriority) => {
        m[rulesetPriority.ruleset_id] = rulesetPriority.priority;
        return m;
      }, {});

      // Update the content wrappers in the store to have the correct priority
      // from the backend response, or clean them up if we don't get them in
      // the response.
      this.store.peekAll('outbound/content-wrapper').forEach((wrapper) => {
        let id = wrapper.contentWrapperId;
        if (id in rulesetPriorityMapping) {
          wrapper.set('priority', rulesetPriorityMapping[id]);
        } else {
          wrapper.rollbackAttributes();
        }
      });

      // Store the new priorities in the store.
      this.store.pushPayload({
        'outbound/content-wrapper': this.store
          .peekAll('outbound/content-wrapper')
          .filter((wrapper) => wrapper.hasDirtyAttributes)
          .map((wrapper) => wrapper.toJSON({ includeId: true })),
      });

      this.notificationsService.notifyConfirmation(
        `The priority of your ${
          humanReadableObjectNames[this.outboundHomeService.activeList]
        } has been reordered`,
      );
    } catch {
      this.notificationsService.notifyError(`Something went wrong. Please try again later.`);

      // If there were any errors, let's rollback all the content wrappers
      // that were updated so that they aren't dirty.
      this.model
        .filter((wrapper) => wrapper.hasDirtyAttributes)
        .forEach((wrapper) => wrapper.rollbackAttributes());
    }
  }),

  resetFilters: action(async function () {
    this.set('isLoading', true);
    this.outboundHomeService.resetFilters();
    this.setActiveFilter(null);
    this._setQueryParams();
    let response = await this._contentSearch();
    this._setPropertiesFromResponse(response);
    this.set('isLoading', false);
  }),

  sortUpdate: action(async function (sortBy) {
    this._toggleSortDirection();
    this.setProperties({ sortBy });
    let response = await this._contentSearch();
    this._setPropertiesFromResponse(response);
  }),

  refresh: action(async function () {
    let response = await this._contentSearch();
    this._setPropertiesFromResponse(response);
  }),

  refreshMessageStates: action(async function () {
    try {
      await this._contentSearch();
    } catch (e) {
      this.notificationsService.notifyError(
        `Something went wrong and we couldn't update message states. Please try again.`,
      );
    }
  }),

  // remove contentWrappers from the store that have been archived and filter out the model.
  deleteContentWrappers: action(async function (archivedIds) {
    let filteredModel = this.model.filter((contentWrapper) => {
      if (!archivedIds.includes(contentWrapper.id)) {
        return contentWrapper;
      } else {
        contentWrapper.deleteRecord();
      }
    });
    set(this, 'model', filteredModel);
  }),

  updateMessageStates: action(function () {
    this.refreshMessageStates();
  }),

  updateMessagesTags: action(function () {
    this.fetchMessagesTags.perform();
  }),

  actions: {
    afterTagSave() {
      this.set('showTagModal', false);
      this.notificationsService.notifyConfirmation('Messages tagged!');
    },
  },

  changeMessagesTags: action(function () {
    this.fetchMessagesTags.perform();
  }),
});
