/* RESPONSIBLE TEAM: team-data-foundations */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
import EmberObject, { computed } from '@ember/object';
import ajax, { put } from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';

let DataAttributeEditor = EmberObject.extend({
  attribute: null,
  event: null,
  appId: null,
  app: null,
  type: 'attribute',
  dependentObjectsUrl: computed('attribute.cda_id', function () {
    return `/ember/data_attributes/${this.attribute.cda_id}/dependent_objects`;
  }),
  onArchive: () => {},
  onUnarchive: () => {},

  archiveTask: task(function* () {
    yield put('/ember/data_attribute_settings/archive', {
      app_id: this.appId,
      id: this.get('attribute.cda_id'),
    });
    this.onArchive();
  }),

  unarchiveTask: task(function* () {
    yield put('/ember/data_attribute_settings/unarchive', {
      app_id: this.appId,
      id: this.get('attribute.cda_id'),
    });
    this.onUnarchive();
  }),

  archive() {
    return this.archiveTask.perform();
  },

  unarchive() {
    return this.unarchiveTask.perform();
  },

  getDependentObjects() {
    return ajax({
      url: this.dependentObjectsUrl,
      type: 'GET',
      data: {
        app_id: this.appId,
        company: this.get('attribute.company'),
      },
    }).then(function (response) {
      return response.table;
    });
  },

  updateDescription() {
    return ajax({
      type: 'PUT',
      url: '/ember/data_attribute_settings/update_description',
      data: JSON.stringify({
        app_id: this.appId,
        id: this.get('attribute.cda_id'),
        description: this.get('attribute.description'),
      }),
    });
  },

  updateType() {
    return ajax({
      type: 'PUT',
      url: `/ember/data_attribute_settings/${this.get('attribute.cda_id')}`,
      data: JSON.stringify({
        app_id: this.appId,
        id: this.get('attribute.cda_id'),
        type: this.get('attribute.type'),
      }),
    });
  },
});

export default DataAttributeEditor;
