/* RESPONSIBLE TEAM: team-ai-agent */
import moment from 'moment-timezone';
import type IntlService from 'embercom/services/intl';
import {
  type PlaygroundTestQuestionSource,
  type PlaygroundTestQuestion,
  type PlaygroundTestRun,
} from './fin-playground';
import { blocksToText } from './open-ai-prompt';
import type RouterService from '@ember/routing/router-service';
import { KNOWLEDGE_HUB_ENTITIES_NAMES } from './knowledge-hub/constants';

export default class FinPlaygroundTestExport {
  app: $TSFixMe;
  intl: IntlService;
  testRun: PlaygroundTestRun;
  router: RouterService;

  constructor(app: $TSFixMe, intl: IntlService, testRun: PlaygroundTestRun, router: RouterService) {
    this.app = app;
    this.testRun = testRun;
    this.intl = intl;
    this.router = router;
  }

  get fileName() {
    return this.intl.t('ai-agent.playground.download-csv.filename', {
      date: moment.tz(this.app.timezone).format('YYYY-MM-DD'),
    });
  }

  get data() {
    let data = this.testRun.questions.map((question) => this.row(question));
    data.unshift(this.headerColumn);

    return data;
  }

  row(question: PlaygroundTestQuestion) {
    return [
      question.questionText,
      this.responseText(question),
      this.sourcesText(question),
      this.answeredState(question),
    ];
  }

  responseText(question: PlaygroundTestQuestion) {
    return blocksToText(question.responseText);
  }

  sourcesText(question: PlaygroundTestQuestion) {
    let sourcesArray = question.sources.map((source) => {
      let entityName = this.entityName(source.entityType);

      return `${source.title} (${this.sourceIntercomUrl(source, entityName)})`;
    });

    return sourcesArray.join('\n');
  }

  sourceIntercomUrl(source: PlaygroundTestQuestionSource, entityName: string) {
    let route = this.router.urlFor(
      'apps.app.knowledge-hub.view',
      this.app.id,
      entityName,
      source.entityId,
    );

    return `${window.location.origin}${route}`;
  }

  answeredState(question: PlaygroundTestQuestion) {
    if (question.customerAnswerRating === 'positive') {
      return this.intl.t('ai-agent.playground.download-csv.answered-state.yes-manually-set');
    } else if (question.customerAnswerRating === 'negative') {
      return this.intl.t('ai-agent.playground.download-csv.answered-state.no-manually-set');
    } else if (question.status === 'completed' && question.isNotDirectAnswer) {
      return this.intl.t('ai-agent.playground.download-csv.answered-state.unrated');
    } else if (question.status === 'completed' && !question.isNotDirectAnswer) {
      return this.intl.t('ai-agent.playground.download-csv.answered-state.yes');
    } else {
      return this.intl.t('ai-agent.playground.download-csv.answered-state.pending');
    }
  }

  entityName(entityType: keyof typeof KNOWLEDGE_HUB_ENTITIES_NAMES) {
    return KNOWLEDGE_HUB_ENTITIES_NAMES[entityType];
  }

  get headerColumn() {
    return [
      this.intl.t('ai-agent.playground.download-csv.headings.question'),
      this.intl.t('ai-agent.playground.download-csv.headings.response'),
      this.intl.t('ai-agent.playground.download-csv.headings.sources'),
      this.intl.t('ai-agent.playground.download-csv.headings.is-answer-correct'),
    ];
  }
}
