/* RESPONSIBLE TEAM: team-reporting */
import PALETTE from '@intercom/pulse/lib/palette';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';

export function buildHorizontalBarChartConfig(config, visualizationType) {
  return {
    xAxis: {
      ...config.xAxis,
      lineColor: 'transparent',
      offset: 0, // remove space between chart and axis when we aren't showing any labels
    },
    yAxis: {
      ...config.yAxis,
      reversedStacks: false,
    },

    plotOptions: {
      ...config.plotOptions,
      bar: {
        borderWidth: 0,
        maxPointWidth: 20,
        minPointLength: 4,
      },
      series: {
        borderWidth: 0,
        states: {
          inactive: {
            opacity: 1,
          },
          hover: {
            enabled: false,
          },
        },
      },
    },
    chart: {
      ...config.chart,
      spacingTop: 0,
    },
    legend: {
      ...config.legend,
      align: visualizationType === VISUALIZATION_TYPES.HORIZONTAL_BAR ? 'center' : 'right',
      itemStyle: {
        color: PALETTE['text-muted'],
        fontWeight: 'normal',
        fontSize: '12px',
      },
      margin: 0,
      enabled: true,
    },
  };
}
