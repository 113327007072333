/* RESPONSIBLE TEAM: team-data-foundations */
import { copy } from 'ember-copy';
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';

export default class TicketExportColumns {
  @service intl;
  @service appService;

  constructor(owner) {
    setOwner(this, owner);
  }

  getColumnData(app) {
    let data = copy(this.columnData, true);
    // remove the columns where the workspace doesn't have the relevant feature
    for (let section of data) {
      section.columns = section.columns
        .filter((column) => column.featureKey === undefined || app.canUseFeature(column.featureKey))
        .filter((column) => column.appModelMixin === undefined || app[column.appModelMixin]);
    }
    return data;
  }

  getColumnNames(app) {
    let namesFromData = this.getColumnData(app).flatMap((section) =>
      section.columns.map((column) => column.name),
    );
    return ['id', 'ticket_url', ...namesFromData];
  }

  get columnData() {
    return [
      {
        sectionTitle: this.intl.t('app.lib.reporting.ticket-export-columns.ticket.title'),
        sectionSubtitle: this.intl.t('app.lib.reporting.ticket-export-columns.ticket.subtitle'),
        sectionName: 'ticket',
        sectionIcon: 'ticket',
        columns: [
          {
            name: 'ticket_id',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.ticket-id.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.ticket-id.hint',
            ),
          },
          {
            name: 'ticket_title',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.title.label',
            ),
          },
          {
            name: 'ticket_description',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.description.label',
            ),
          },
          {
            name: 'ticket_status',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.ticket-status.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.ticket-status.hint',
            ),
          },
          {
            name: 'ticket_state',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.ticket-state.label',
            ),
          },
          {
            name: 'ticket_category',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.ticket-category.label',
            ),
          },
          {
            name: 'shared_with_customer',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.shared-with-customer.label',
            ),
          },
          {
            name: 'ticket_type_id',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.ticket-type-id.label',
            ),
          },
          {
            name: 'ticket_type_name',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.ticket-type-name.label',
            ),
          },
          {
            name: 'created_from',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.created-from.label',
            ),
          },
          {
            name: 'created_at',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.created-at.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.created-at.hint',
            ),
          },
          {
            name: 'submitted_at',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.submitted-at.label',
            ),
          },
          {
            name: 'last_updated_at',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.last-updated-at.label',
            ),
          },
          {
            name: 'last_resolved_at',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.last-resolved-at.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.last-resolved-at.hint',
            ),
          },
          {
            name: 'time_zone',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.time-zone.label',
            ),
          },
          {
            name: 'conversation_custom_data_attributes',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.ticket-attributes.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.ticket.columns.ticket-attributes.hint',
            ),
          },
        ],
      },
      {
        sectionTitle: this.intl.t(
          'app.lib.reporting.ticket-export-columns.customer-information.title',
        ),
        sectionSubtitle: this.intl.t(
          'app.lib.reporting.ticket-export-columns.customer-information.subtitle',
        ),
        sectionName: 'customer',
        sectionIcon: 'lead-qualification',
        columns: [
          {
            name: 'company_ids',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.company-ids.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.company-ids.hint',
            ),
          },
          {
            name: 'company_names',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.company-names.label',
            ),
          },
          {
            name: 'company_tags',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.company-tags.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.company-tags.hint',
            ),
          },
          {
            name: 'user_email',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.user-email.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.user-email.hint',
            ),
          },
          {
            name: 'user_location',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.user-location.label',
            ),
          },
          {
            name: 'user_name',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.user-name.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.user-name.hint',
            ),
          },
          {
            name: 'user_type',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.user-type.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.user-type.hint',
            ),
          },
          {
            name: 'participated_user_ids',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.participated-user-ids.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.participated-user-ids.hint',
            ),
          },
          {
            name: 'participated_names',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.participated-names.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.participated-names.hint',
            ),
          },
          {
            name: 'participated_emails',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.participated-emails.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.participated-emails.hint',
            ),
          },
          {
            name: 'user_tags',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.user-tags.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.user-tags.hint',
            ),
          },
          {
            name: 'user_id',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.user-id.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.customer-information.columns.user-id.hint',
            ),
          },
        ],
      },
      {
        sectionTitle: this.intl.t('app.lib.reporting.ticket-export-columns.teammate.title'),
        sectionSubtitle: this.intl.t('app.lib.reporting.ticket-export-columns.teammate.subtitle'),
        sectionName: 'teammate',
        sectionIcon: 'team',
        columns: [
          {
            name: 'assignee_id',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.assignee-id.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.assignee-id.hint',
            ),
          },
          {
            name: 'assignee_name',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.assignee-name.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.assignee-name.hint',
            ),
          },
          {
            name: 'team_assignee_id',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.team-assignee-id.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.team-assignee-id.hint',
            ),
          },
          {
            name: 'team_assignee_name',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.team-assignee-name.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.team-assignee-name.hint',
            ),
          },
          {
            name: 'created_by_admin_id',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.created-by-id.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.created-by-id.hint',
            ),
          },
          {
            name: 'created_by_admin_name',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.created-by-name.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.created-by-name.hint',
            ),
          },
          {
            name: 'resolved_by_admin_id',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.resolved-by-id.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.resolved-by-id.hint',
            ),
          },
          {
            name: 'resolved_by_admin_name',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.resolved-by-name.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.resolved-by-name.hint',
            ),
          },
          {
            name: 'teammates_participated',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.teammates-participated.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.teammates-participated.hint',
            ),
          },
          {
            name: 'time_to_resolve',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.time-to-resolve.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.time-to-resolve.hint',
            ),
          },
          {
            name: 'time_in_submitted',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.time-in-submitted.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.time-in-submitted.hint',
            ),
          },
          {
            name: 'time_in_progress',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.time-in-progress.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.time-in-progress.hint',
            ),
          },
          {
            name: 'time_in_waiting_on_customer',
            label: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.time-in-waiting-on-customer.label',
            ),
            hint: this.intl.t(
              'app.lib.reporting.ticket-export-columns.teammate.columns.time-in-waiting-on-customer.hint',
            ),
          },
        ],
      },
    ];
  }
}
