/* RESPONSIBLE TEAM: team-data-foundations */
const DEFAULT_ATTRIBUTES = ['id', 'type'];

export default class EntityNode {
  constructor(reference) {
    this.id = reference.id;
    this.value = reference.label;
    this.type = reference.type;

    let extraAttributes = Object.keys(reference).filter(
      (attribute) => !DEFAULT_ATTRIBUTES.includes(attribute),
    );
    extraAttributes.forEach((attribute) => {
      this[attribute] = reference[attribute];
    });
  }
}
