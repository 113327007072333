/* RESPONSIBLE TEAM: team-product-exploration */
import { inject as service } from '@ember/service';
import Helper from '@ember/component/helper';
import type Router from '@ember/routing/router-service';

const EXCLUDED_ROUTES = ['teams-checkout', 'checkout', 'developer-hub', 'external-link'];

export default class DisplayNavBarInLoadingScreenHelper extends Helper {
  @service declare router: Router;

  get currentURL() {
    return this.router.currentURL;
  }

  compute() {
    return !EXCLUDED_ROUTES.any((route) => this.currentURL.includes(route));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'display-nav-bar-in-loading-screen': typeof DisplayNavBarInLoadingScreenHelper;
  }
}
