/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import DefaultLinechartConfig from 'embercom/lib/reporting/flexible/default-line-chart-config';
import PALETTE from '@intercom/pulse/lib/palette';

export const TARGETLINEPALETTE = {
  default: PALETTE['text-muted'],
  limitOn: PALETTE['text-muted'],
  limitReached: PALETTE['notice-border-emphasis'],
  contractedUsage: PALETTE['text-muted'],
};

export const TARGETLINECLASS = {
  default: 'text-default bg-neutral-container',
  limitOn: 'text-default bg-neutral-container',
  limitReached: 'text-on-notice bg-notice-fill',
  contractedUsage: 'text-default bg-neutral-container',
};

export default class CustomUsageLineChartConfig extends DefaultLinechartConfig {
  setSpacingRight(value: number): void {
    this.config.chart.spacingRight = value;
  }

  useDarkTooltips(): void {
    this.config.tooltip.backgroundColor = PALETTE['neutral-fill'];
    this.config.tooltip.borderColor = PALETTE['neutral-border'];
    this.config.tooltip.style.color = PALETTE['text-on-neutral'];
    this.config.tooltip.shadow.color = PALETTE['border-shadow-dark'];
    this.config.tooltip.shadow.width = 1;
  }

  plotLine(
    value: number,
    formattedValue: string,
    options?: { palette: string; lineClass: string; dashStyle?: string },
  ) {
    return {
      color: options?.palette || TARGETLINEPALETTE.default,
      width: 2,
      value,
      zIndex: 5,
      dashStyle: options?.dashStyle || 'solid',
      label: {
        align: 'left',
        verticalAlign: 'middle',
        x: -15,
        y: 2,
        useHTML: true,
        formatter() {
          return `
              <span class='data-test-linechart-label flex font-medium rounded-lg whitespace-nowrap px-2 h-5 items-center text-center justify-center ${options?.lineClass || TARGETLINECLASS.default}'>
                ${formattedValue}
              </span>
            `;
        },
      },
    };
  }

  setTargetLine(
    value: $TSFixMe,
    formattedValue: $TSFixMe,
    options?: { palette: string; lineClass: string; dashStyle?: string },
  ) {
    this.config.yAxis.plotLines = [this.plotLine(value, formattedValue, options)];
  }

  setAdditionalTargetLine(
    value: $TSFixMe,
    formattedValue: $TSFixMe,
    options?: { palette: string; lineClass: string; dashStyle?: string },
  ) {
    this.config.yAxis.plotLines.push(this.plotLine(value, formattedValue, options));
  }
}
