/* RESPONSIBLE TEAM: team-help-desk-experience */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import type RouterService from '@ember/routing/router-service';

export default class LinkWithText extends Helper {
  @service router!: RouterService;
  @service session!: Session;

  compute(params: [string, any | undefined, string]) {
    let [route, id, text] = params;
    let href = this.router.urlFor(route, this.session.workspace.id, id);

    return sanitizeHtml(`<a class="hover:text-link  no-underline" href=${href}>${text}</a>`);
  }

  compute_with_href(href: string, text: string, underline?: boolean) {
    return sanitizeHtml(
      `<a class="hover:text-link  ${!underline && 'no-underline'}" href=${href}>${text}</a>`,
    );
  }
}
