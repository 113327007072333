/* RESPONSIBLE TEAM: team-data-foundations */
import { tracked } from '@glimmer/tracking';
import BaseVisibilityHelper from 'embercom/helpers/visibility/base-visibility-helper';

export default class HiddenSegmentsHelper extends BaseVisibilityHelper {
  @tracked segments;

  compute(params) {
    this.segments = params[0];
    return this.hiddenSegments;
  }
}
