/* RESPONSIBLE TEAM: team-reporting */
import { copy } from 'ember-copy';
import DefaultHighchartConfig from 'embercom/lib/reporting/flexible/default-highchart-config';
import Tooltip from 'embercom/lib/reporting/flexible/tooltip';
import PALETTE from '@intercom/pulse/lib/palette';

const config = {
  title: '',
  credits: false,
  legend: {
    enabled: true,
    align: 'center',
    margin: 27,
    layout: 'horizontal',
    itemHoverStyle: { color: null, cursor: 'pointer' },
    symbolPadding: 1,
    itemMarginBottom: 5,
  },
  xAxis: {
    lineColor: 'transparent',
    type: 'datetime',
    dateTimeLabelFormats: {
      hour: '%l%p',
      day: '%b %e',
      week: '%b %e',
    },
    offset: 7,
    tickLength: 0,
    labels: {},
  },

  yAxis: {
    softMax: 2, // Used in cases where we have no data, the chart defaults to yAxis max so it looks visually "correct".
    allowDecimals: false,
    gridLineColor: PALETTE['neutral-border'],
    offset: 10,
    endOnTick: true,
    maxPadding: 0,
    title: {
      enabled: false,
    },
    labels: {},
  },

  chart: {
    spacingLeft: 3,
    spacingBottom: 0,
  },
  tooltip: new Tooltip({
    shadow: {
      color: PALETTE['text-muted'],
      offsetX: 0,
      offsetY: 0,
      opacity: 1,
      width: 6,
    },
  }),
};

export default class FlexibleLineChartConfig extends DefaultHighchartConfig {
  constructor(timezone) {
    super();
    this.config = copy(config, true);
    this.setTimezone(timezone);
  }

  setXAxisTickInterval(interval) {
    this.config.xAxis.tickInterval = interval;
  }

  setYAxisTickInterval(interval) {
    this.config.yAxis.tickInterval = interval;
  }

  setXAxisType(type) {
    this.config.xAxis.type = type;
  }
}
