import type Chart from 'embercom/models/reporting/custom/chart';

/* RESPONSIBLE TEAM: team-reporting */
export const VISUALIZATION_TYPE_GRID_SIZES = {
  bar: {
    minWidth: 3,
    minHeight: 4,
    defaultWidth: 6,
    defaultHeight: 5,
  },
  counter: {
    minWidth: 2,
    minHeight: 2,
    defaultWidth: 3,
    defaultHeight: 3,
  },
  heatmap: {
    minWidth: 4,
    minHeight: 4,
    defaultWidth: 12,
    defaultHeight: 8,
  },
  tabular: {
    minWidth: 3,
    minHeight: 4,
    defaultWidth: 12,
    defaultHeight: 8,
  },
  line: {
    minWidth: 3,
    minHeight: 4,
    defaultWidth: 6,
    defaultHeight: 5,
  },
  'horizontal-bar': {
    minWidth: 3,
    minHeight: 2,
    defaultWidth: 6,
    defaultHeight: 3,
  },
  area: {
    minWidth: 3,
    minHeight: 4,
    defaultWidth: 6,
    defaultHeight: 5,
  },
  donut: {
    minWidth: 2,
    minHeight: 5,
    defaultWidth: 3,
    defaultHeight: 6,
  },
  'column-line': {
    minWidth: 3,
    minHeight: 4,
    defaultWidth: 6,
    defaultHeight: 5,
  },
  banner: {
    minWidth: 3,
    minHeight: 1,
    defaultWidth: 12,
    defaultHeight: 1,
  },
  'data-table': {
    minWidth: 12,
    minHeight: 6,
    defaultWidth: 12,
    defaultHeight: 6,
  },
};

export function setDefaultSizesOnChart(chart: Chart) {
  let { defaultWidth, defaultHeight } = getDefaultSizesBasedOnVizType(chart.visualizationType);
  chart.gridWidth = defaultWidth;
  chart.gridHeight = defaultHeight;
}

export function getDefaultSizesBasedOnVizType(visualizationType: string) {
  let { defaultWidth, defaultHeight } =
    VISUALIZATION_TYPE_GRID_SIZES[visualizationType as keyof typeof VISUALIZATION_TYPE_GRID_SIZES];
  return { defaultWidth, defaultHeight };
}
