/* RESPONSIBLE TEAM: team-reporting */
import Tooltip from 'embercom/lib/reporting/flexible/tooltip';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import countFormatter from 'embercom/lib/count-formatter';
import { formattedRangeText, signalHasImproved } from 'embercom/lib/reporting/csf-query-helper';
import { toPercentString } from 'embercom/lib/percentage-formatter';
import Highcharts from 'highcharts';
import ConversationalSupportFunnelComparisonTooltipTemplate from 'embercom/templates-raw/reporting/conversational-support-funnel/comparison-tooltip';
import PALETTE from '@intercom/pulse/lib/palette';

export default class HorizontalbarBuilder {
  constructor({ viewConfig, dataConfig, chartData = [{ data: [] }], range }) {
    this.chartData = chartData;
    this.viewConfig = viewConfig;
    this.dataConfig = dataConfig;
    this.range = range;
    this._allowHighchartsAttributes();
  }

  _allowHighchartsAttributes() {
    // From Highcharts 9.x you need to specifically opt in any custom data- attributes
    Highcharts.AST.allowedAttributes.push('data-test-csf-tooltip');
    Highcharts.AST.allowedAttributes.push('data-test-csf-comparison-tooltip');
    Highcharts.AST.allowedAttributes.push('data-test-csf-comparison-tooltip-arrow');
  }

  buildTheme() {
    let config = {
      title: '',
      credits: false,
      chart: {
        type: 'bar',
        height: '25px',
        margin: [0, 0, 0, 0],
        style: {
          fontFamily:
            'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
        },
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        min: 0,
        max: this.getStackTotal(),
        visible: false,
        endOnTick: true,
        maxPadding: 0,
      },
      legend: {
        reversed: true,
        align: 'right',
        itemStyle: {
          color: PALETTE['text-muted'],
          fontWeight: 'normal',
          fontSize: '13px',
        },
        useHTML: true,
        labelFormatter: this.legendFormatter(),
        symbolHeight: 10,
        margin: 0,
        padding: 0,
        enabled: false,
      },
      colors: this.buildColors(),
      plotOptions: this.buildPlotOptions(),
      tooltip: this.buildTooltipConfig(),
    };

    return config;
  }

  buildPlotOptions() {
    let series = {
      stacking: 'normal',
      pointWidth: 18,
      states: {
        hover: {
          enabled: false,
        },
      },
    };
    if (this.viewConfig.disableLegendInteraction) {
      series.events = {
        legendItemClick: () => false,
      };
      series.states.inactive = {
        opacity: 1,
      };
    }
    return {
      series,
    };
  }

  buildTooltipConfig() {
    let tooltip = new Tooltip({
      formatter: this.formatComparisonTooltip(),
    });
    return tooltip;
  }

  legendFormatter() {
    let { disabledLegends } = this.viewConfig;
    return function () {
      let classes = disabledLegends && disabledLegends[this.name] ? 'text-muted' : '';
      return sanitizeHtml(`<span class="${classes}">${this.name}</span>`);
    };
  }

  formatComparisonTooltip() {
    let rangeText = formattedRangeText(this.range);
    let signalHasImprovedFn = signalHasImproved();
    let tooltipTextMappingFunction = this.viewConfig.tooltipTextMappingFunction;
    let disableTooltip = this.viewConfig.disableTooltip;

    return function () {
      if (disableTooltip) {
        return false;
      }

      let tooltipText = this.point.series.name;
      if (tooltipTextMappingFunction) {
        tooltipText = tooltipTextMappingFunction(this.point.series.name);
      }
      let text = sanitizeHtml(
        `<span class="font-bold"> of ${countFormatter(
          this.point.stackTotal,
        )}</span> ${tooltipText}`,
      );
      let value = this.y;
      let previousValue = this.point.series.userOptions.previousValue;
      let improvementDirection = this.point.series.userOptions.improvementDirection;
      let delta = Math.abs(value - previousValue);
      let signalHasIncreased = value > previousValue;

      let context = {
        currentValue: countFormatter(value),
        previousValue: countFormatter(previousValue),
        delta,
        deltaText: countFormatter(delta),
        signalHasIncreased,
        signalHasImproved: signalHasImprovedFn(signalHasIncreased, improvementDirection),
        rangeText,
        text,
        emoji: this.point.series.userOptions.emoji,
        percentageValue: toPercentString((value / this.point.stackTotal) * 100),
      };
      return ConversationalSupportFunnelComparisonTooltipTemplate(context);
    };
  }

  getStackTotal() {
    if (this.chartData.length === 0) {
      return null;
    }
    return this.chartData.reduce((n, { data }) => n + data[0], 0);
  }

  buildColors() {
    return this.chartData.map((series) => {
      return this.viewConfig.seriesColorMap[series.name];
    });
  }
}
