/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable no-restricted-imports */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { USER_TYPES } from 'embercom/lib/filters-to-text';
import {
  AVAILABLE_CONTINENT_MAPPINGS,
  AVAILABLE_CHANNEL_MAPPINGS,
  AVAILABLE_FIN_ANSWER_TYPE_MAPPINGS,
  AVAILABLE_FIN_RESOLUTION_STATE_MAPPINGS,
  AVAILABLE_SLA_TARGET_TYPE_MAPPINGS,
  COLOR_MAPPINGS,
  MEDIAN,
  MEAN,
  MAX,
  MIN,
  COUNT,
  RANGE,
  CARDINALITY,
  TEAM_PROPERTY_IDENTIFIERS,
  TEAMMATE_PROPERTY_IDENTIFIERS,
  DEFAULT_HEATMAP_COLORS,
  CSAT_COLOR_MAPPINGS,
  CSAT_METRICS,
  SERIES_COLOR_PALETTE,
} from 'embercom/lib/reporting/flexible/constants';
import {
  getEmberDataStore,
  getApp,
  getAttributeService,
  getTicketStateService,
} from 'embercom/lib/container-lookup';
import Admin from 'embercom/models/admin';
import { CONVERSATION_STATE_LABELS } from 'embercom/lib/inbox/constants';
import { CONVERSATION_RATINGS } from 'predicates/models/data/conversation-ratings';
import { lookupCountryName } from 'embercom/lib/countries';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import Formatters from 'embercom/lib/reporting/flexible/formatters';
import moment from 'moment-timezone';
import { emojiScaleOptions } from 'embercom/models/data/survey/constants';
import TicketType from 'embercom/models/inbox/ticket-type';
import { capitalize } from '@ember/string';
import { isPresent } from '@ember/utils';
import containerLookup from 'embercom/lib/container-lookup';
import PALETTE from '@intercom/pulse/lib/palette';
import { mapXAxisLabel } from 'embercom/lib/reporting/flexible/label-formatter';

const REPORTING_CONVERSATION_RATINGS = [
  {
    value: 'Unknown',
    displayName: 'No rating',
  },
  {
    value: 'Other',
    displayName: 'Other',
  },
  ...CONVERSATION_RATINGS,
];

const CONVERSATION_RATINGS_VALUE_TO_LABEL_MAPPING = Object.fromEntries([
  ...REPORTING_CONVERSATION_RATINGS.map((r) => [r.value, r.displayName]),
]);

const NUMBERS_REGEX = /\d+/;

const HOVER_STATE_COLOR_WEIGHT = 30;
const DEFAULT_COLOR_WEIGHT = 50;

export function buildFunctionToMapFromDatabaseValueToDisplayName(propertyIdentifier) {
  function fetchMappingOrReturnValue(availableMappings, value) {
    return availableMappings[value] || value;
  }
  if (!propertyIdentifier) {
    return null;
  }
  let store = getEmberDataStore();
  if (TEAMMATE_PROPERTY_IDENTIFIERS.has(propertyIdentifier)) {
    return (value) => teamOrTeammateLabel.bind({ store })(value?.toString(), 'Former Teammate');
  }
  if (TEAM_PROPERTY_IDENTIFIERS.has(propertyIdentifier)) {
    return (value) => teamOrTeammateLabel.bind({ store })(value?.toString(), 'Former Team');
  }
  if (propertyIdentifier === 'has_calls') {
    return (value) => {
      if (value === 0 || value === '0') {
        return 'No calls';
      } else if (value === 1 || value === '1') {
        return 'With calls';
      }
    };
  }
  if (
    propertyIdentifier === 'call_types' ||
    propertyIdentifier === 'direction' ||
    propertyIdentifier === 'call_type'
  ) {
    return capitalize;
  }
  if (propertyIdentifier === 'in_office_hours') {
    let intl = containerLookup('service:intl');
    return (value) => {
      if (value === 0 || value === '0') {
        return intl.t('reporting.calls.out_of_office_hours');
      } else if (value === 1 || value === '1') {
        return intl.t('reporting.calls.in_office_hours');
      }
    };
  }
  if (propertyIdentifier === 'state') {
    return (value) => {
      let intl = containerLookup('service:intl');
      let translationKey = `reporting.calls.state.${value}`;
      if (intl.exists(translationKey)) {
        return intl.t(translationKey);
      } else {
        console.warn(`Missing translation key for call state: ${value}`);
        return value;
      }
    };
  }
  if (propertyIdentifier === 'has_voicemail') {
    return (value) => {
      let intl = containerLookup('service:intl');
      if (value === 0 || value === '0') {
        return intl.t('reporting.calls.has_voicemail.no');
      } else if (value === 1 || value === '1') {
        return intl.t('reporting.calls.has_voicemail.yes');
      }
    };
  }

  if (
    propertyIdentifier === 'conversation_tag_ids' ||
    propertyIdentifier === 'user.manual_tag_ids' ||
    propertyIdentifier === 'companies.manual_tag_ids' ||
    propertyIdentifier === 'manual_tag_ids'
  ) {
    return (value) => tagLabel.bind({ store })(value);
  }
  if (propertyIdentifier === 'user.role' || propertyIdentifier === 'user_role') {
    return (value) => USER_TYPES[value];
  }
  if (
    propertyIdentifier === 'conversation_rating.rating_index' ||
    propertyIdentifier === 'bot_conversation_rating.rating_index' ||
    propertyIdentifier === 'workflow_conversation_rating.rating_index' ||
    propertyIdentifier === 'rating_index'
  ) {
    return (value) => CONVERSATION_RATINGS_VALUE_TO_LABEL_MAPPING[value] || 'No rating';
  }
  if (
    propertyIdentifier === 'workflow_conversation_rating.workflow_id' ||
    propertyIdentifier === 'rated_actor_id.workflow_id' ||
    propertyIdentifier === 'rating_participants.workflow_ids' ||
    propertyIdentifier === 'replied.workflow_ids'
  ) {
    let reportingUnderlyingDataService = containerLookup('service:reportingUnderlyingDataService');

    return (value) => {
      return reportingUnderlyingDataService.mappedValueFor(
        value,
        'conversation.workflow_conversation_rating.workflow_id',
      );
    };
  }
  if (propertyIdentifier === 'participation_group') {
    let intl = containerLookup('service:intl');

    return (value) =>
      ({
        human_only: intl.t(
          'components.reporting.custom.chart-builder.filter-bar.rating-participation-group-filter.human_only',
        ),
        'human_and_ai-agent': intl.t(
          'components.reporting.custom.chart-builder.filter-bar.rating-participation-group-filter.human_and_ai-agent',
        ),
        human_and_workflow: intl.t(
          'components.reporting.custom.chart-builder.filter-bar.rating-participation-group-filter.human_and_workflow',
        ),
        'ai-agent_only': intl.t(
          'components.reporting.custom.chart-builder.filter-bar.rating-participation-group-filter.ai-agent_only',
        ),
        workflow_only: intl.t(
          'components.reporting.custom.chart-builder.filter-bar.rating-participation-group-filter.workflow_only',
        ),
      })[value] || 'None';
  }
  if (propertyIdentifier === 'rated_actor_type' || propertyIdentifier === 'agent_types_involved') {
    let intl = containerLookup('service:intl');
    let ACTOR_MAPPINGS = {
      'ai-agent': intl.t(
        'components.reporting.custom.chart-builder.filter-bar.agent-type-filter.ai-agent',
      ),
      human: intl.t('components.reporting.custom.chart-builder.filter-bar.agent-type-filter.human'),
      workflow: intl.t(
        'components.reporting.custom.chart-builder.filter-bar.agent-type-filter.workflow',
      ),
    };

    return (value) => ACTOR_MAPPINGS[value];
  }
  if (propertyIdentifier === 'user.location.country_code') {
    return lookupCountryName;
  }
  if (propertyIdentifier === 'user.location.continent_code') {
    return (value) => AVAILABLE_CONTINENT_MAPPINGS[value] || value;
  }
  if (propertyIdentifier === 'channel_type') {
    return (value) => AVAILABLE_CHANNEL_MAPPINGS[value] || value;
  }
  if (propertyIdentifier === 'conversation_state') {
    return (value) => CONVERSATION_STATE_LABELS[value] || value;
  }
  if (
    propertyIdentifier === 'companies.plan_id' ||
    propertyIdentifier === 'conversation.companies.plan_id'
  ) {
    return (value) => planLabel(value);
  }
  if (propertyIdentifier === 'ticket_type_id') {
    return (value) => ticketTypeLabel.bind({ store })(value?.toString());
  }

  if (propertyIdentifier === 'fin.last_sent_answer_type') {
    return (value) => fetchMappingOrReturnValue(AVAILABLE_FIN_ANSWER_TYPE_MAPPINGS, value);
  }
  if (propertyIdentifier === 'fin.resolution_state') {
    return (value) => AVAILABLE_FIN_RESOLUTION_STATE_MAPPINGS[value] || value;
  }

  if (
    propertyIdentifier === 'ticket_state_category' ||
    propertyIdentifier === 'current_ticket_state_category'
  ) {
    return (value) => getTicketStateService().getTranslatedSystemState(value);
  }

  if (
    propertyIdentifier === 'ticket_custom_state_id' ||
    propertyIdentifier === 'current_ticket_custom_state_id'
  ) {
    return (value) => getTicketStateService().getTicketCustomStateById(value).adminLabel;
  }

  if (propertyIdentifier === 'replied.fin_ai_agent') {
    return (value) => (value ? 'True' : 'False');
  }

  if (
    propertyIdentifier === 'fin.participated' ||
    propertyIdentifier === 'rating_participants.fin'
  ) {
    return (value) => {
      if (value === 0 || value === '0' || value === 'remaining') {
        return 'Uninvolved conversations';
      } else if (value === 1 || value === '1') {
        return 'Involved conversations';
      } else if (value === true) {
        return 'True';
      } else if (value === false) {
        return 'False';
      }
    };
  }

  if (propertyIdentifier === 'sla_target_type') {
    return (value) => AVAILABLE_SLA_TARGET_TYPE_MAPPINGS[value] || value;
  }
  if (propertyIdentifier === 'conversation_sla_id') {
    let reportingUnderlyingDataService = containerLookup('service:reportingUnderlyingDataService');

    return (value) => {
      return reportingUnderlyingDataService.mappedValueFor(
        value,
        'conversation_sla_status_log.conversation_sla_id',
      );
    };
  }

  if (propertyIdentifier === 'part_actions') {
    let intl = containerLookup('service:intl');
    return (value) => {
      return intl.t(`reporting.data-table.part-actions.${value}`);
    };
  }

  let descriptor = ConversationAttributeDescriptor.peekByIdentifier(propertyIdentifier);

  if (descriptor) {
    return (value) => conversationAttributeLabel(value, descriptor);
  }

  let custom_data = findUserCustomDataAttributeByIdentifier(propertyIdentifier);

  if (custom_data) {
    return (value) => value;
  }
  if (propertyIdentifier.startsWith('companies.custom_data')) {
    return (value) => value;
  }

  if (propertyIdentifier === 'conversation_started_by_user') {
    return (value) => {
      let intl = containerLookup('service:intl');
      return value
        ? intl.t(
            'components.reporting.custom.chart-builder.filter-bar.started-by-user-filter.inbound-text',
          )
        : intl.t(
            'components.reporting.custom.chart-builder.filter-bar.started-by-user-filter.outbound-text',
          );
    };
  }

  return null;
}

export function buildFunctionToMapFromValueToSeriesColor(propertyIdentifier) {
  if (!propertyIdentifier) {
    return null;
  }

  if (!COLOR_MAPPINGS[propertyIdentifier]) {
    return null;
  }

  return (value) => COLOR_MAPPINGS[propertyIdentifier]?.[value] || null;
}

export function buildColorsForViewBy(chartData, xAxisAttributeKey) {
  let colorsForSeries = [];
  chartData.data.forEach((dataPoint, index) => {
    let labelToRaw = chartData.rawToLabelMapping?.[dataPoint[0]] || dataPoint[0];
    let { color } = mapValueToColors(xAxisAttributeKey, labelToRaw);
    let paletteColor = color ? PALETTE[color] : PALETTE['text-muted'];
    colorsForSeries.push(paletteColor);
  });
  chartData.colors = colorsForSeries;
}

export function buildColorsForSegments(chartData, yAxisAttributeKey) {
  chartData.forEach((series, index) => {
    let { color, hoverColor } = mapValueToColors(yAxisAttributeKey, series.unMappedName);
    series.color = color ? PALETTE[color] : PALETTE['neutral-container'];
    series.hoverColor = hoverColor ? PALETTE[hoverColor] : PALETTE['neutral-container-emphasis'];
  });
}

export function buildColorsForHeatmap(metricId) {
  let colorMappings = CSAT_METRICS.includes(metricId)
    ? CSAT_COLOR_MAPPINGS
    : DEFAULT_HEATMAP_COLORS;
  let colors = [
    {
      stops: colorMappings.map(([value, color]) => [value, PALETTE[color]]),
    },
  ];
  return colors;
}

export function buildColorsForTimeComparison(chartData) {
  let currentMetrics = chartData.reject((series) => series.isPreviousPeriod);
  let baseColors = currentMetrics.map(
    (_, i) => SERIES_COLOR_PALETTE[i % SERIES_COLOR_PALETTE.length],
  );
  let currentColors = baseColors.map((color) => {
    return PALETTE[`${color}-50`];
  });

  let previousColors = baseColors.map((color) => {
    return PALETTE[`${color}-80`];
  });

  return [...previousColors, ...currentColors];
}

export function mapHumanReadableLabelsToRawKey(dataResponse, dataConfig, viewConfig) {
  let group = dataResponse.groups[0];
  let xAxisType = dataConfig.xAxis.type;
  let labelToKeyMapping = {};

  group.values.forEach((key, _) => {
    if (xAxisType === 'nominal') {
      let humanReadableLabel = mapXAxisLabel(dataConfig, viewConfig, key);
      labelToKeyMapping[humanReadableLabel] = key;
    }
  });
  return labelToKeyMapping;
}

export function mapValueToColors(attributeKey, value) {
  // Defaults to the list defined here: https://github.com/intercom/embercom/blob/master/app/lib/reporting/flexible/constants.js#L94
  // if no mapping is defined for the attributeKey we fall back to the hashing function
  let colorMappingFunction = buildFunctionToMapFromValueToSeriesColor(attributeKey);
  return {
    color: colorMappingFunction
      ? colorMappingFunction(value)
      : mapPropertyIdentifierToConsistentColor(attributeKey, value),
    hoverColor: colorMappingFunction
      ? colorMappingFunction(value)
      : mapPropertyIdentifierToConsistentColor(attributeKey, value, true),
  };
}

export function mapPropertyIdentifierToConsistentColor(key, attributeValue, isHoverColor = false) {
  if (!attributeValue) {
    return null;
  }
  if (attributeValue === 'Other') {
    return isHoverColor ? 'gray' : 'gray-light';
  }
  if (attributeValue === 'Unknown') {
    return isHoverColor ? 'gray-dark' : 'gray';
  }

  let color = containerLookup('service:reportingChartService').getColorForSeries(
    key,
    attributeValue.toString(),
  );

  return `${color}-${isHoverColor ? HOVER_STATE_COLOR_WEIGHT : DEFAULT_COLOR_WEIGHT}`;
}

function conversationAttributeLabel(value, descriptor) {
  return value === 'Other' ? value : descriptor.getDisplayableValue(value);
}

export function labelMapperFallback(mapperFunction) {
  return mapperFunction && ((value) => mapperFunction(value) || 'Not specified');
}

export function tagLabel(value) {
  if (!value) {
    return 'Not tagged';
  }
  if (value === 'Other') {
    return value;
  }
  let matchingTag = this.store.peekRecord('tag', value);
  if (matchingTag) {
    return matchingTag.name;
  }
  return 'Not tagged';
}

function planLabel(value) {
  if (value === 'Unknown' || value === 'Other') {
    return value;
  }
  let app = getApp();
  return app.plans.findBy('id', value).name;
}

function ticketTypeLabel(value) {
  if (value === 'Unknown' || value === 'Other') {
    return value;
  }

  let tickets = TicketType.peekAllAndMaybeLoad() || [];

  let ticket = tickets.findBy('id', value);

  if (ticket) {
    return ticket.archived ? `${ticket.name} (Archived)` : ticket.name;
  }

  return ticket;
}

function teamOrTeammateLabel(value, defaultLabel) {
  if (!value.match(NUMBERS_REGEX)) {
    return value;
  }
  let admin = Admin.peekAndMaybeLoad(this.store, value);
  return admin.isUnknown ? defaultLabel : admin.displayAs;
}

export function formatTableValue(value, metricUnit) {
  if (value === '' || value === null || value === undefined) {
    return value;
  }
  let formatter = new Formatters[metricUnit]();
  return formatter.formatCounter(value);
}

export function formatCsvValue(value, metricUnit) {
  if (value === '-') {
    return value;
  } else if (metricUnit === 'seconds') {
    return Math.round(value).toString();
  } else {
    return formatTableValue(value, metricUnit);
  }
}

export function emojiScale(emojiScale, value) {
  return emojiScaleOptions[emojiScale].findBy('value', parseInt(value, 10)).unicodeEmoticon;
}

function findUserCustomDataAttributeByIdentifier(identifier) {
  let attributeService = getAttributeService();

  let [prefix, cda_id] = identifier.split('#');
  if (prefix !== 'user.custom_data' || !cda_id) {
    return null;
  }

  return attributeService.userAttributesOnly
    .filter((d) => d.isUserCustomData && !d.archived)
    .find((d) => d.cda_id === cda_id || d.cda_id === parseInt(cda_id, 10));
}

export function formatTimestamp(interval, timestamp_in_seconds, reportTimezone) {
  let currentMoment = moment.unix(timestamp_in_seconds).tz(getTimezone(reportTimezone));
  if (interval === 'week') {
    return `${currentMoment.format(timeFormatForInterval(interval))} - ${currentMoment
      .add(6, 'days')
      .format(timeFormatForInterval(interval))}`;
  } else {
    return currentMoment.format(timeFormatForInterval(interval));
  }
}

function getTimezone(reportTimezone) {
  if (isPresent(reportTimezone)) {
    return reportTimezone;
  }
  let app = getApp();
  return app.timezone;
}

export function mapValueToLabel(type, value) {
  let mappingFunction = buildFunctionToMapFromDatabaseValueToDisplayName(type);
  if (!mappingFunction) {
    return value;
  }

  return mappingFunction(value);
}

function timeFormatForInterval(interval) {
  if (interval === 'hour') {
    return 'MMM D, h:mm a';
  } else if (interval === 'month') {
    return 'MMM';
  } else {
    return 'MMM D';
  }
}

export function aggregationConfig() {
  return {
    [MEDIAN]: {
      value: MEDIAN,
      text: containerLookup('service:intl').t('reporting.aggregations.median'),
    },
    [MEAN]: {
      value: MEAN,
      text: containerLookup('service:intl').t('reporting.aggregations.mean'),
    },
    [MAX]: {
      value: MAX,
      text: containerLookup('service:intl').t('reporting.aggregations.max'),
    },
    [MIN]: {
      value: MIN,
      text: containerLookup('service:intl').t('reporting.aggregations.min'),
    },
    [COUNT]: { value: COUNT, text: '' },
    [RANGE]: { value: RANGE, text: '' },
    [CARDINALITY]: { value: CARDINALITY, text: '' },
  };
}

export function seriesNameForMetric(metricName, aggregationName, chartWithChrome) {
  if (chartWithChrome && aggregationName) {
    return `${aggregationConfig()[aggregationName].text} ${metricName.toLowerCase()}`;
  } else {
    return metricName;
  }
}

export function getChartSeriesName(index, renderableChart) {
  if (renderableChart?.isMultimetric && index < renderableChart?.chartSeries.length) {
    return renderableChart?.chartSeries.objectAt(index).metricDisplayName;
  }
  return undefined;
}
export function getTeammates() {
  let admins = getApp().humanAdmins;
  return admins.reduce((acc, a) => {
    acc[a.id] = {
      id: a.id,
      name: a.name,
      avatar: a.avatar,
    };

    return acc;
  }, {});
}

export function getTeams() {
  let teamsWithUnassigned = [getApp().unassignedAdmin].concat(getApp().teams);
  return teamsWithUnassigned?.reduce((acc, t) => {
    acc[t.id] = {
      id: t.id,
      name: t.name,
      avatar: t.avatar,
    };

    return acc;
  }, {});
}
