/* RESPONSIBLE TEAM: team-help-desk-experience */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import type InboxHotkeys from 'embercom/services/inbox-hotkeys';
import { type HotkeyID } from 'embercom/services/inbox-hotkeys/HotkeyID';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default class BuildHtmlForHotKeys extends Helper<{
  Args: { Positional: [`${HotkeyID}`] };
  Return: string;
}> {
  @service declare inboxHotkeys: InboxHotkeys;

  compute([id]: [`${HotkeyID}`]) {
    let hotkey = this.inboxHotkeys.hotkeysMap[id] ?? { display: [] };

    return sanitizeHtml(`
    <div class="inline-flex gap-1">
      ${hotkey.display.map((key) => this.tagForKey(key)).join('')}
    </div>
    `);
  }

  private tagForKey(key: string) {
    return `<div class="inbox2__shortcut-key flex items-center justify-center rounded border border-neutral-border  text-muted  text-xs">${key}</div>`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'build-html-for-hotkeys': typeof BuildHtmlForHotKeys;
  }
}
