/* RESPONSIBLE TEAM: team-reporting */
import ChartConfig from 'embercom/lib/reporting/flexible/default-column-chart-config';
import Formatters from 'embercom/lib/reporting/flexible/formatters';
import IntervalFormatter from 'embercom/lib/reporting/flexible/interval-formatter';
import Axis from 'embercom/lib/reporting/flexible/axis';
import moment from 'moment-timezone';
import PALETTE, { PALETTE_COLOR_NAMES } from '@intercom/pulse/lib/palette';
import { isPresent } from '@ember/utils';
import {
  HORIZONTAL_BAR_TYPES,
  VISUALIZATION_TYPES,
} from 'embercom/models/reporting/custom/visualization-options';
import { buildHorizontalBarChartConfig } from 'embercom/lib/reporting/custom/horizontal-bar-chart-helper';
import {
  buildColorsForSegments,
  buildColorsForViewBy,
  buildColorsForTimeComparison,
} from 'embercom/lib/reporting/custom/view-config-builder-helpers';
import { STACKING_TYPES } from 'embercom/lib/reporting/custom/view-config-builder';
import PercentFormatter from 'embercom/lib/reporting/flexible/formatters/percent';
import { SERIES_COLOR_PALETTE } from 'embercom/lib/reporting/flexible/constants';

const MAX_LABELS = 12;
const MAX_LABELS_FOR_NOMINAL_AXIS = 20;
const MIN_CHART_WIDTH_FOR_NOMINAL_AXIS_LABELS = 500;
const HOVER_STATE_THRESHOLD = 10;
export const TICK_INTERVAL_IN_MS = {
  hour: 3600 * 1000,
  day: 24 * 3600 * 1000,
  week: 24 * 3600 * 1000 * 7,
  month: 24 * 3600 * 1000 * 30,
};

const SERIES_COLOR_PALETTE_CLASSMAP = {
  'vis-azure-50': 'text-vis-azure-50',
  'vis-yellow-50': 'text-vis-yellow-50',
  'vis-mint-50': 'text-vis-mint-50',
  'vis-salmon-50': 'text-vis-salmon-50',
  'vis-slateblue-50': 'text-vis-slateblue-50',
  'vis-olive-50': 'text-vis-olive-50',
  'vis-pink-50': 'text-vis-pink-50',
  'vis-turquoise-50': 'text-vis-turquoise-50',
  'vis-orange-50': 'text-vis-orange-50',
  'vis-blue-50': 'text-vis-blue-50',
};

export const HOVER_STATE_COLOR_WEIGHT = 30;
const DEFAULT_COLOR_WEIGHT = 50;
const paletteToColor = (baseColor, weight) => PALETTE[`${baseColor}-${weight}`];
export const SERIES_COLORS = SERIES_COLOR_PALETTE.map((baseColor) =>
  paletteToColor(baseColor, DEFAULT_COLOR_WEIGHT),
);
export const seriesColor = (index, weight = DEFAULT_COLOR_WEIGHT) =>
  paletteToColor(SERIES_COLOR_PALETTE[index % SERIES_COLOR_PALETTE.length], weight);
const colorToName = (color) => PALETTE_COLOR_NAMES.find((name) => PALETTE[name] === color);
export const colorToClass = (color) => SERIES_COLOR_PALETTE_CLASSMAP[colorToName(color)];
const MERGE_TOOLTIP_RESOLUTION = 4;

export default class SerieschartBuilder {
  constructor({
    range,
    viewConfig,
    dataConfig,
    seriesColors = undefined,
    width = '',
    chartData = [{ data: [] }],
    chartType = 'column',
    app,
    isMultimetric,
    isTimeComparison,
  }) {
    this.range = range;
    this.seriesColors = seriesColors;
    this.xAxisType = dataConfig.xAxis?.type;
    this.width = width;
    this.chartData = chartData;
    this.viewConfig = viewConfig;
    this.dataConfig = dataConfig;
    this.formatter = new Formatters[this.viewConfig.formatUnit.unit](
      this.viewConfig.formatUnit.displayUnit,
    );
    this.yAxisTicker = new Axis[this.viewConfig.formatUnit.unit]();
    this.stacking = viewConfig.columnChart?.stacking;
    this.interval = dataConfig.xAxis?.data?.interval;
    this.chartType = chartType;
    this.defaultColors = this.seriesColors || SERIES_COLORS;
    this.isSegmented = isPresent(this.dataConfig.yAxis);
    this.app = app;
    this.isMultimetric = isMultimetric;
    this.isTimeComparison = isTimeComparison;
  }

  get intervalFormatter() {
    return new IntervalFormatter(this.interval);
  }

  buildTheme(visualizationType = undefined) {
    let config = new ChartConfig(this.range.timezone);

    config.setChartType(this.chartType);
    config.setXAxisType(this.isXAxisTemporal ? 'datetime' : 'category');

    if (this.isXAxisTemporal) {
      config.setXAxisTickInterval(this.xAxisTickInterval);
    }

    if (this.viewConfig.grouping?.tickPositioner) {
      config.setXAxisTickPositioner(this.viewConfig.grouping.tickPositioner);
    }

    if (
      this.xAxisLabelCount > MAX_LABELS &&
      this.isXAxisTemporal &&
      this.viewConfig.skipLabelsForLargeSeries
    ) {
      config.setStep(this._getStepForConfig());
    }

    config.setYAxisTickInterval(this.yAxisTickInterval);
    config.setYAxisRanges(this.viewConfig.yAxis?.min, this.yAxisMax);
    config.setTooltipFormatter(this.viewConfig.tooltipFormatter || this.tooltipFormatter);

    if (this.isXAxisTemporal) {
      config.setXAxisFormatter(this.temporalLabelFormatter);
      // last X axis label is clipped without the extra spacing (default is 10)
      config.setSpacingRight(15);
    } else if (
      this.totalNumberOfColumns >= MAX_LABELS_FOR_NOMINAL_AXIS &&
      this.dataConfig.xAxis?.data?.limit >= MAX_LABELS_FOR_NOMINAL_AXIS
    ) {
      config.setXAxisFormatter(this.nominalLabelFormatter);
    }

    // Using formatData make it work, but I think it cries out the need to split formatter for axis and tooltip
    config.setYAxisFormatter(({ value }) => this.formatter.formatAxis(value));

    this._setConsistentColors(config);

    config.setMinimumPointLength(4);

    if (
      this.viewConfig.labelStyleOverrides &&
      isPresent(this.viewConfig.labelStyleOverrides.fontSize)
    ) {
      config.setXAxisFontSize(this.viewConfig.labelStyleOverrides.fontSize);
    }

    if (this.viewConfig.useDarkTooltips) {
      config.useDarkTooltips();
    }

    if (this.viewConfig.disableLegend) {
      config.disableLegend();
    }

    if (this.viewConfig.colorByPoint) {
      config.setColorByPoint(this.viewConfig.colorByPoint);
    }

    if (HORIZONTAL_BAR_TYPES.includes(visualizationType)) {
      let horizontalBarChartConfig = buildHorizontalBarChartConfig(
        config.config,
        visualizationType,
      );
      config.config = {
        ...config.config,
        ...horizontalBarChartConfig,
      };
      if (this.stacking && !this.isSegmented) {
        config.disableLabelsAndGridlines();
        if (
          visualizationType === VISUALIZATION_TYPES.HORIZONTAL_BAR_WITH_COUNTER ||
          !this.app?.canSeeR2Beta
        ) {
          config.config.chart.height = 90;
        }
      } else if (this.isXAxisTemporal) {
        config.config.xAxis.reversed = false; // this ensures we show the latest date at the top
      }
    }

    if (this.stacking) {
      config.setStacking(this.stacking, this.chartType);
    }
    this._enableHoverState(config);

    if (this.viewConfig.showDataLabels) {
      config.config.plotOptions.series.dataLabels = this.dataLabels;
      config.setDataLabelFormatter(this.dataLabelFormatter);
    }

    if (isPresent(this.targetValue)) {
      let percentageFormatter = new PercentFormatter();
      let formattedValue =
        this.viewConfig.columnChart?.stacking === STACKING_TYPES.PERCENT
          ? percentageFormatter.formatAxis(this.targetValue)
          : this.formatter.formatAxis(this.targetValue);

      config.setTargetLine(this.targetValue, formattedValue);
    }

    return config.config;
  }

  get targetValue() {
    let value = this.viewConfig.visualizationOptions?.target?.value;
    if (isNaN(value)) {
      return undefined;
    }

    return value;
  }

  get dataLabels() {
    return {
      enabled: true,
      color: PALETTE['text-default'],
      style: {
        fontSize: '12px',
        fontWeight: 'normal',
        textOutline: `1px ${PALETTE['base-module']}`,
      },
    };
  }

  get xAxisTickInterval() {
    if (this.isIntervalHourly) {
      return this._tickIntervalForHourlyBreakdown();
    } else {
      return TICK_INTERVAL_IN_MS[this.interval];
    }
  }

  get minAndMaxValues() {
    let maxValue = 0;
    let minValue = 0;

    this.chartData.forEach((graphData) => {
      graphData.data.forEach((timestampWithValue) => {
        if (timestampWithValue[1] > maxValue) {
          maxValue = Math.round(timestampWithValue[1]);
        }

        if (timestampWithValue[1] < minValue) {
          minValue = Math.round(timestampWithValue[1]);
        }
      });
    });
    return { minValue, maxValue };
  }

  get yAxisTickInterval() {
    if (isPresent(this.viewConfig.yAxis?.tickInterval)) {
      return this.viewConfig.yAxis.tickInterval;
    }

    let { minValue, maxValue } = this.minAndMaxValues;
    maxValue = Math.max(maxValue, this.targetValue || 0);

    let diff = maxValue - minValue;
    if (diff === 0) {
      return 5; // Used to split the yAxis.softMax option of Highcharts into even ticks when we don't have data.
    }
    return this.yAxisTicker.interval(minValue, maxValue);
  }

  get yAxisMax() {
    if (isPresent(this.viewConfig.yAxis?.max)) {
      return this.viewConfig.yAxis.max;
    }

    if (isPresent(this.targetValue)) {
      let { maxValue } = this.minAndMaxValues;
      // If the target value is greater than the max data value, use the target value as the max
      // otherwise, let the chart handle the max value
      return this.targetValue > maxValue ? this.targetValue : null;
    }

    return null;
  }

  get temporalLabelFormatter() {
    let config = this;

    return function () {
      let currentLabel = moment.tz(this.value, this.chart.options.time.timezone);
      let format = 'MMM D';
      let maxLabelsForSmallWidths = 8;

      if (config.isIntervalHourly) {
        if (config.width === 'small' && parseInt(currentLabel.format('H'), 10) % 3 !== 0) {
          return '';
        } else if (config.xAxisTickInterval === TICK_INTERVAL_IN_MS.day / 2) {
          // Render '12pm' on every second label when tickInterval is 1/2 a day
          let numberOfTicksFromStart =
            currentLabel.diff(config.range.startMoment) / config.xAxisTickInterval;
          if (numberOfTicksFromStart % 2 !== 0 && !this.isFirst) {
            return '12pm';
          } else {
            format = 'MMM D';
          }
        } else if (config.xAxisTickInterval === TICK_INTERVAL_IN_MS.hour) {
          format = 'hA';
        } else if (config.xAxisTickInterval === TICK_INTERVAL_IN_MS.month) {
          format = 'MMM';
        }

        // Hide the last label in hourly time breakdown with a daily range
        if (this.isLast && config.range.interval === 'day') {
          return '';
        }
      }

      if (
        config.isIntervalDaily &&
        config.width === 'small' &&
        config.range.endMoment.diff(config.range.startMoment) >
          maxLabelsForSmallWidths * 1000 * 60 * 60 * 24
      ) {
        if (config.range.startMoment.diff(currentLabel, 'days') % 2 !== 0) {
          return '';
        }
      }

      if (
        config.isIntervalWeekly &&
        config.range.endMoment.diff(config.range.startMoment) >
          maxLabelsForSmallWidths * 1000 * 60 * 60 * 24 * 7
      ) {
        let firstLabel = config.range.startMoment.clone().startOf('isoWeek');

        if (config.width === 'small' && firstLabel.diff(currentLabel, 'days') % 14 !== 0) {
          return '';
        }
      }

      if (config.isIntervalMonthly) {
        if (config.width === 'small' && parseInt(currentLabel.format('M'), 10) % 2 !== 1) {
          return '';
        } else {
          format = 'MMM';
        }
      }

      return currentLabel.format(format);
    };
  }

  get xAxisLabelCount() {
    return this.chartData.firstObject.data.length;
  }

  get hideXLabelsForCustomReport() {
    return (
      this.dataConfig.xAxis?.data?.limit > MAX_LABELS_FOR_NOMINAL_AXIS &&
      this.xAxisLabelCount > MAX_LABELS_FOR_NOMINAL_AXIS
    );
  }

  get nominalLabelFormatter() {
    let config = this;
    return function () {
      return this.chart.chartWidth <= MIN_CHART_WIDTH_FOR_NOMINAL_AXIS_LABELS ||
        config.hideXLabelsForCustomReport
        ? null
        : this.value;
    };
  }

  pointName(highchart) {
    return this.chartType === 'column' ? highchart.point.name : highchart.point.x;
  }

  percentageString(percentage) {
    return `${Math.round(percentage * 100) / 100}%`;
  }

  dotHTMLString(color) {
    return `<span class="${colorToClass(color)}">\u25CF</span>`;
  }

  dotHTMLStringFromHex(color) {
    return `<span style=color:${color}>\u25CF</span>`;
  }

  arePointsCloseEnough(pointA, pointB, radius) {
    return Math.abs(pointA - pointB) <= (radius || MERGE_TOOLTIP_RESOLUTION);
  }

  mergeTooltips(highchart, showPercentages) {
    let point = highchart.point;
    let coincidedSeries = highchart.series.chart.series.filter(
      (series) =>
        series.visible &&
        this.arePointsCloseEnough(
          series.data[point.index].plotY,
          point.plotY,
          point.graphic?.radius,
        ),
    );

    let mergedParts = coincidedSeries.map((series) => {
      let dotPart;
      dotPart = `${this.dotHTMLStringFromHex(series.color)} `;

      let tooltipValuePart = `${this.formatter.formatTooltip(
        series.data[point.index].y,
        series.options.tooltipDisplayUnit,
        series.name,
      )}`;

      let pointInSeries = series.points[highchart.point.index];
      let tooltipPercentagePart =
        showPercentages && pointInSeries.percentage
          ? `${this.percentageString(pointInSeries.percentage)}`
          : null;
      let seriesNamePart = ` ${series.name}</br>`;

      let percentAndTooltipPart = this.buildPercentAndTooltipPart(
        tooltipValuePart,
        tooltipPercentagePart,
      );

      return [dotPart, percentAndTooltipPart, seriesNamePart].flat().join('');
    });

    return mergedParts.join('');
  }

  get tooltipFormatter() {
    let config = this;
    let isXAxisTemporal = this.isXAxisTemporal;
    let groupingTransformation = this.viewConfig.grouping?.tooltipTransformation;
    let useDarkTooltips = this.viewConfig.useDarkTooltips;
    let showPercentages = this.stacking && this.viewConfig.showPercentages;
    let isHorizontalBar = this.viewConfig.chartType === VISUALIZATION_TYPES.HORIZONTAL_BAR;

    return function () {
      let timezone = this.series.chart.options.time.timezone;
      let datePart;

      if (groupingTransformation) {
        datePart = groupingTransformation(this.point, this.series);
      } else if (isHorizontalBar && config.stacking && !config.isSegmented) {
        // for stacked unsegmented charts, each series contains a single point, so we use the series name
        datePart = this.series.name;
      } else if (isXAxisTemporal) {
        if (
          this.series.options.isComparison &&
          config.isIntervalMonthly &&
          this.point.index === 0 &&
          this.series.index < this.series.chart.series.length / 2 // we do this for multimetrics charts, the first half of the charts (n/2) will be for previous periods
        ) {
          let startDate = config.range.startMoment.subtract(config.range.inDays, 'd');
          datePart = config.intervalFormatter.datePart(startDate, timezone);
        } else if (this.point.index === 0 && config.isIntervalMonthly) {
          let nextPoint = this.series.points[this.point.index + 1];
          let endDate = nextPoint
            ? moment(nextPoint.x || nextPoint.name).subtract(1, 'd') // Line charts use x, column charts use name
            : undefined;
          datePart = config.intervalFormatter.datePart(config.range.startMoment, timezone, endDate);
        } else if (
          this.series.options.isComparison &&
          this.series.chart.series.length === 2 &&
          this.series.index === 0 &&
          this.series.options.data
        ) {
          let pointData = this.series.options.data[this.point.index];
          let comparisonDateRange = pointData[pointData.length - 1];
          datePart = config.intervalFormatter.datePart(comparisonDateRange, timezone);
        } else if (this.point.index === this.series.points?.length - 1) {
          datePart = config.intervalFormatter.datePart(
            config.pointName(this),
            timezone,
            config.range.endMoment,
          );
        } else {
          datePart = config.intervalFormatter.datePart(config.pointName(this), timezone);
        }
      } else {
        datePart = this.point.name;
      }

      let valuePart = `${config.formatter.formatTooltip(
        this.y,
        this.series.options.tooltipDisplayUnit,
        this.series.name,
      )}`;
      let contextPart;

      if (this.series?.points[0]?.series?.options?.custom) {
        contextPart = `${this.series.points[0].series.options.custom.value} in ${this.series.points[0].series.options.custom.total} conversations`;
      }

      if (useDarkTooltips) {
        if (config.viewConfig.mergeTooltipsForCoincidentPoints) {
          valuePart = config.mergeTooltips(this, showPercentages);
        } else {
          valuePart = config.buildTooltipsForNonCoincidentPoints(
            config,
            this,
            valuePart,
            contextPart,
          );
        }
        valuePart = `<span class='text-white'>${valuePart}</span>`;
      }

      let cssClass = 'reporting__highcharts-tooltip';

      // Check if we are in a 2 series time comparison chart
      // and we are on the previous series
      if (
        this.series.options.isComparison &&
        this.series.chart.series.length === 2 &&
        this.series.index === 0
      ) {
        cssClass += ' reporting__report-tab-tooltip__title';
      }
      if (datePart) {
        return `<div class='${cssClass}'><strong>${valuePart}</strong><br/>(${datePart})</div>`;
      } else {
        return `<div class='${cssClass}'><strong>${valuePart}</strong></div>`;
      }
    };
  }

  buildTooltipsForNonCoincidentPoints(config, highCharts, valuePart, contextPart) {
    let percentagePart =
      config.viewConfig.showPercentages && highCharts.percentage
        ? `${config.percentageString(highCharts.percentage)}`
        : null;
    let pointsValuesPart = config.viewConfig.showValues ? ` (${highCharts.point.value})` : null;
    let tooltipContextPart = contextPart
      ? ` • <span class="text-white">${contextPart}</span>`
      : null;

    let parts = config
      .buildPercentAndTooltipPart(valuePart, percentagePart, contextPart)
      .concat([pointsValuesPart, tooltipContextPart]);

    if ((config.stacking && config.isSegmented) || config.viewConfig.showLegendInTooltips) {
      let dotString = highCharts.series.color
        ? config.dotHTMLStringFromHex(highCharts.series.color)
        : config.dotHTMLString(config.defaultColors[highCharts.series.colorIndex]);

      parts.push(`</br>${dotString} ${highCharts.series.name}</br>`);
    }
    return parts.compact().join('');
  }

  buildPercentAndTooltipPart(valuePart, percentagePart, contextPart) {
    // The context part contains the values, so we don't want to show the valuePart again
    if (contextPart && percentagePart) {
      return [percentagePart];
    } else if (this.stacking === STACKING_TYPES.PERCENT) {
      return percentagePart ? [percentagePart, ' - ', valuePart] : [valuePart];
    } else {
      return [valuePart, this.addBracketIfValue(percentagePart)].compact();
    }
  }

  get dataLabelFormatter() {
    let config = this;

    return function () {
      let percentagePart =
        config.viewConfig.showPercentages &&
        this.percentage &&
        config.stacking === STACKING_TYPES.PERCENT
          ? `${config.percentageString(this.percentage)}`
          : null;

      if (percentagePart) {
        return `<div>${percentagePart}</div>`;
      }

      let valuePart = `${config.formatter.formatTooltip(
        this.y,
        this.series.options.tooltipDisplayUnit,
        this.series.name,
      )}`;

      return `<div>${valuePart}</div>`;
    };
  }

  get isIntervalHourly() {
    return this.interval === 'hour';
  }

  addBracketIfValue(value) {
    if (value) {
      return ` (${value})`;
    }
    return value;
  }

  get isIntervalDaily() {
    return this.interval === 'day';
  }

  get isIntervalWeekly() {
    return this.interval === 'week';
  }

  get isIntervalMonthly() {
    return this.interval === 'month';
  }

  get isXAxisTemporal() {
    return this.xAxisType === 'temporal';
  }

  _getStepForConfig() {
    let step = 2;
    if (this.isIntervalHourly && this.xAxisTickInterval !== TICK_INTERVAL_IN_MS.hour) {
      // In hourly mode, set the step to 1 so that we display every label
      step = 1;
    } else if (this.isIntervalDaily) {
      // In daily mode, set the step according to the max number of labels we can display
      step = Math.ceil(this.range.inDays / MAX_LABELS);
    } else if (this.isIntervalWeekly) {
      let weeks = this.range.inDays / 7;
      step = Math.ceil(weeks / MAX_LABELS);
    }
    return step;
  }

  _enableHoverState(config) {
    if (this.shouldEnableHoverState) {
      let chartData = this.chartData;
      chartData.forEach((series, index) => {
        let hoverColor = series.hoverColor || seriesColor(index, HOVER_STATE_COLOR_WEIGHT);
        series.states = {
          inactive: {
            opacity: 0.48,
          },
          hover: {
            enabled: true,
            color: hoverColor,
          },
        };

        if (this.stacking) {
          series.stickyTracking = false;
          series.states.hover = {
            ...series.states.hover,
            borderColor: hoverColor,
            borderWidth: 2,
          };
        } else if (this.chartType === 'area') {
          series.stickyTracking = true;
        }
      });
    }
  }

  _setConsistentColors(config) {
    if (this.seriesColors) {
      config.setColors(this.seriesColors);
      return;
    }

    if (this.dataConfig.yAxis || this.stacking) {
      if (this.dataConfig.yAxis?.type === 'temporal' || this.isMultimetric) {
        config.setColors(this.defaultColors);
        return;
      }

      let attributeKey = this.dataConfig.yAxis
        ? this.dataConfig.yAxis?.data.property
        : this.dataConfig.xAxis?.data.property;
      buildColorsForSegments(this.chartData, attributeKey);
    } else {
      if (this.dataConfig.xAxis?.type === 'temporal' || this.isMultimetric) {
        let colors = this.defaultColors;
        if (this.isTimeComparison) {
          colors = buildColorsForTimeComparison(this.chartData, this.defaultColors);
        }

        config.setColors(colors);
        return;
      }

      config.setColorByPoint(true);
      this.chartData.forEach((series, _) => {
        buildColorsForViewBy(series, this.dataConfig.xAxis.data.property);
      });
    }
  }

  get totalNumberOfColumns() {
    let maxSeriesLength = Math.max(...this.chartData.map((series) => series.data.length));
    let totalNumberOfColumns = 0;
    for (let i = 0; i < maxSeriesLength; i++) {
      let anySeriesHasANonZeroPointAtThisIndex = this.chartData.some(
        (series) => series.data[i] && series.data[i][1] > 0,
      );
      if (anySeriesHasANonZeroPointAtThisIndex) {
        totalNumberOfColumns++;
      }
    }
    return totalNumberOfColumns;
  }

  get shouldEnableHoverState() {
    if (this.viewConfig.shouldEnableHoverState || this.isIntervalHourly) {
      return true;
    }
    return this.totalNumberOfColumns > HOVER_STATE_THRESHOLD;
  }

  _tickIntervalForHourlyBreakdown() {
    if (this.range.interval === 'day') {
      // If number of days in range is <= 7, return half the normal tick interval
      // so that we have twice as many ticks and can render '12pm' on each alternate label
      if (this.range.inDays <= 7) {
        return TICK_INTERVAL_IN_MS.day / 2;
      } else {
        return TICK_INTERVAL_IN_MS.day;
      }
    } else {
      return TICK_INTERVAL_IN_MS[this.range.interval];
    }
  }
}
