/* RESPONSIBLE TEAM: team-data-foundations */
import { tracked } from '@glimmer/tracking';
import BaseVisibilityHelper from 'embercom/helpers/visibility/base-visibility-helper';

export default class VisibleTagsHelper extends BaseVisibilityHelper {
  @tracked taggings;

  compute(params) {
    this.taggings = params[0];
    return this.visibleTaggings;
  }
}
