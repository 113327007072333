/* RESPONSIBLE TEAM: team-frontend-tech */
import Helper from '@ember/component/helper';
import emoji from 'embercom/lib/emoji';
import { highlightText } from 'embercom/helpers/render-blocks';
import { inject as service } from '@ember/service';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import type IntlService from 'ember-intl/services/intl';
import type RegionService from 'embercom/services/region-service';
import type Inbox2TrustedDomainsForInboundEmailLinks from 'embercom/services/inbox2-trusted-domains-for-inbound-email-links';
import { imageHasNoLinkOrHostedOnIntercom, isIntercomCdnLink } from './render-blocks';

// @ts-ignore
import HtmlCleaner from '@intercom/embercom-composer/lib/html-cleaner';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
// @ts-ignore
import { icInterfaceIcon } from '@intercom/pulse/helpers/ic-interface-icon';

let listeningToKeydown = false;
let listeningToMouseScroll = false;

function getImageName(str: string) {
  let fileName = new URL(str).pathname.split('/').pop() || '';
  return fileName.length > 50 ? `${fileName.substring(0, 50)}...` : fileName;
}
function wrapInContainer(html: string, imageAsText = false, intl: IntlService) {
  let outerNode = document.createElement('div');
  let childNode = document.createElement('div');
  outerNode.classList.add('intercom-interblocks');
  childNode.classList.add('intercom-interblocks-html');
  childNode.innerHTML = sanitizeHtml(html);
  outerNode.appendChild(childNode);

  if (imageAsText) {
    let images = outerNode.getElementsByTagName('img');

    Array.from(images).forEach((image) => {
      let imageTextDiv = document.createElement('div');
      image.classList.add('rhsb-side-convo-desc__popover-html-img-hidden');
      imageTextDiv.classList.add('rhsb-side-convo-desc__popover-img-details');
      imageTextDiv.classList.add('truncate');
      let imageName = getImageName(image.src);
      imageTextDiv.innerHTML = sanitizeHtml(
        `${intl.t('inbox.common.block-as-text.sent-image')} "${imageName}"`,
      );
      image.after(imageTextDiv);
    });
  }
  return outerNode;
}

interface TeammateLinkDomainPolicy {
  id: number;
  policy_type: 'domain' | 'url';
  policy_action: 'trusted' | 'blocked';
  item: string;
}

// Content might be plaintext, HTML or both
// To display text breaks correctly in Inbox we need to replace double newlines with an empty paragraph
// https://github.com/intercom/intercom/issues/242758
function parseDoubleNewlines(html: string) {
  return html.replace(/\n\n/gm, '<p><br></p>');
}

export function addExternalLinkShield(
  node: HTMLElement,
  externalLinkShieldEnabled: boolean,
  maliciousLinks: string[],
  maliciousLinkBlockingEnabled: boolean,
  trustedDomains: string[],
  trustedDomainsAndUrls: TeammateLinkDomainPolicy[],
  blockedDomainsAndUrls: TeammateLinkDomainPolicy[],
  blockedDomainsAndUrlsEnabled: boolean,
  appId?: string,
) {
  createExternalLinkTooltip();

  let showEvents = ['mouseenter', 'focus'];
  let hideEvents = ['mouseleave', 'blur'];

  showEvents.forEach((event) => {
    node.addEventListener(
      event,
      (event: MouseEvent) => {
        showExternalLinkTooltip(
          event,
          externalLinkShieldEnabled,
          maliciousLinkBlockingEnabled,
          blockedDomainsAndUrlsEnabled,
        );
      },
      true,
    );
  });

  hideEvents.forEach((event) => {
    node.addEventListener(event, hideExternalLinkTooltip);
  });

  let links = node.getElementsByTagName('a');

  Array.from(links).forEach((link: HTMLAnchorElement) => {
    if (link.href.length > 0) {
      // We don't want to show the tooltip when link is to current page
      // This case happens specifically when href has been stripped of js content
      if (link.href === `${window.location.href}#`) {
        return;
      }

      // TODO - this should be removed once we migrate away from the legacy trusted domains and urls
      if (trustedDomainsAndUrls.length === 0) {
        trustedDomainsAndUrls = trustedDomains.map((url: string) => ({
          id: 0,
          policy_type: 'domain',
          policy_action: 'trusted',
          item: url,
        }));
      }

      link.classList.add('external-link');
      let sanitizedUrl = link.href;
      let isUrlTrusted = urlMatchesAnyDomainOrUrlPolicy(
        sanitizedUrl,
        'trusted',
        trustedDomainsAndUrls,
      );
      let isContactLink = link.href.startsWith('mailto:') || link.href.startsWith('tel:');
      let trustedOrContactLink = isUrlTrusted || isContactLink;
      let isMaliciousLink = urlMatchesMaliciousLink(sanitizedUrl, maliciousLinks);
      let isBlockedUrl = urlMatchesAnyDomainOrUrlPolicy(
        sanitizedUrl,
        'blocked',
        blockedDomainsAndUrls,
      );

      let external_link_url = `/a/external-link?href=${encodeURIComponent(sanitizedUrl)}`;
      if (appId && appId !== '') {
        external_link_url += `&app_id=${appId}`;
      }

      if (trustedOrContactLink || !externalLinkShieldEnabled) {
        link.href = sanitizedUrl;
      } else {
        link.setAttribute('data-external-link-url', external_link_url);
      }

      if (maliciousLinkBlockingEnabled && !trustedOrContactLink && isMaliciousLink) {
        link.classList.add('malicious-link');
        external_link_url += `&malicious=true`;
        link.setAttribute('data-external-link-url', external_link_url);
      }

      if (blockedDomainsAndUrlsEnabled && !trustedOrContactLink && isBlockedUrl) {
        link.classList.add('blocked-link');
        external_link_url += `&blocked=true`;
        link.setAttribute('data-external-link-url', external_link_url);
      }

      // If the link contains an image, show an icon on top of the image
      showIconOverImage(
        link,
        isUrlTrusted,
        isMaliciousLink,
        isBlockedUrl,
        externalLinkShieldEnabled,
      );
    }
  });
}

function hideTooltipOnKeydown() {
  // Add this listener only once
  if (!listeningToKeydown) {
    document.addEventListener('keydown', hideExternalLinkTooltip, true);
    listeningToKeydown = true;
  }
}

function hideTooltipOnMouseScroll() {
  // Add this listener only once
  if (!listeningToMouseScroll) {
    document.addEventListener('scroll', hideExternalLinkTooltip, true);
    listeningToMouseScroll = true;
  }
}

function getExternalLinkIcon(
  type: 'trusted' | 'untrusted' | 'malicious' | 'blocked',
  externalLinkShieldEnabled = true,
) {
  if (type === 'trusted' || !externalLinkShieldEnabled) {
    return icInterfaceIcon(['link'], { color: 'gray' });
  } else if (type === 'untrusted') {
    return icInterfaceIcon(['bot-info'], { color: 'orange' });
  } else if (type === 'malicious') {
    return icInterfaceIcon(['alert'], { color: 'red' });
  } else if (type === 'blocked') {
    return icInterfaceIcon(['unsubscribed'], { color: 'gray' });
  }
}

function getLinkSVGIconDOMElement(
  type: 'trusted' | 'untrusted' | 'malicious' | 'blocked',
  externalLinkShieldEnabled = true,
) {
  let icon = getExternalLinkIcon(type, externalLinkShieldEnabled);
  let parser = new DOMParser();
  let doc = parser.parseFromString(icon.string, 'image/svg+xml');
  return doc.documentElement;
}

function showIconOverImage(
  linkNode: HTMLAnchorElement,
  isUrlTrusted: boolean,
  isMaliciousLink: boolean,
  isBlockedUrl: boolean,
  externalLinkShieldEnabled = true,
) {
  let images = linkNode.querySelectorAll('img');
  images &&
    images.forEach((img) => {
      if (imageHasNoLinkOrHostedOnIntercom(img)) {
        return;
      }
      img.classList.add('external-link-image');

      let svgNode;
      if (isUrlTrusted) {
        svgNode = getLinkSVGIconDOMElement('trusted', externalLinkShieldEnabled);
      } else if (isMaliciousLink) {
        svgNode = getLinkSVGIconDOMElement('malicious', externalLinkShieldEnabled);
      } else if (isBlockedUrl) {
        svgNode = getLinkSVGIconDOMElement('blocked', externalLinkShieldEnabled);
      } else {
        svgNode = getLinkSVGIconDOMElement('untrusted', externalLinkShieldEnabled);
      }

      let imageContainer = document.createElement('div');
      imageContainer.classList.add('external-link-image-container');
      let svgContainer = document.createElement('span');
      svgContainer.classList.add('external-link-svg-container');
      svgContainer.appendChild(svgNode);

      if (externalLinkShieldEnabled) {
        if (isMaliciousLink) {
          imageContainer.classList.add('malicious-link');
          svgContainer.classList.add('malicious-link');
        } else if (isBlockedUrl) {
          imageContainer.classList.add('blocked-link');
          svgContainer.classList.add('blocked-link');
        } else if (!isUrlTrusted) {
          imageContainer.classList.add('untrusted-link');
          svgContainer.classList.add('untrusted-link');
        }
      }

      img.parentNode?.replaceChild(imageContainer, img); // Replace the img element with the image container
      imageContainer.appendChild(img); // Append the img element to the image container
      imageContainer.appendChild(svgContainer); // Append the svg to the image container
    });
}

function createExternalLinkTooltip() {
  let tooltipElement = document.getElementById('external-link-tooltip');

  if (!tooltipElement) {
    let tooltip = document.createElement('span');
    tooltip.classList.add('external-link-tooltip');
    tooltip.setAttribute('id', 'external-link-tooltip');
    tooltip.setAttribute('data-test-external-link-tooltip', '');
    document.body.appendChild(tooltip);
  }
}

function showExternalLinkTooltip(
  event: MouseEvent,
  externalLinkShieldEnabled: boolean,
  maliciousLinkBlockingEnabled: boolean,
  blockedDomainsAndUrlsEnabled: boolean,
) {
  // event listener is set on the node so we don't have a listener per link
  // but useCapture on the listner will send an event per chid element, we only want the link element
  let target = event.target;
  if (!(target instanceof HTMLAnchorElement)) {
    return;
  }
  let linkElement = target.classList.contains('external-link') ? target : undefined;
  if (!linkElement || !linkElement.href) {
    return;
  }

  let tooltipURL;
  try {
    tooltipURL = new URL(linkElement.href);
  } catch (e) {
    console.error(e);
    return;
  }

  if (isIntercomCdnLink(tooltipURL.hostname)) {
    return;
  }

  let tooltipContent;
  let tooltipElement = document.getElementById('external-link-tooltip');
  if (!tooltipElement) {
    return;
  }
  tooltipElement?.classList.add('opacity-100');
  let externalLinkHref = tooltipURL.href;
  let isUntrustedLink = linkElement.dataset.externalLinkUrl;

  if (
    (externalLinkShieldEnabled || maliciousLinkBlockingEnabled || blockedDomainsAndUrlsEnabled) &&
    isUntrustedLink
  ) {
    if (linkElement.classList.contains('malicious-link')) {
      let linkIcon = getExternalLinkIcon('malicious');
      tooltipContent = `${linkIcon}${htmlToTextContent(externalLinkHref || '')}`;
      tooltipElement.classList.add('malicious-link');
      tooltipElement.classList.remove('untrusted-domain');
      tooltipElement.classList.remove('blocked-link');
    } else if (linkElement.classList.contains('blocked-link')) {
      let linkIcon = getExternalLinkIcon('blocked');
      tooltipContent = `${linkIcon}${htmlToTextContent(externalLinkHref || '')}`;
      tooltipElement.classList.add('blocked-link');
      tooltipElement.classList.remove('untrusted-domain');
      tooltipElement.classList.remove('malicious-link');
    } else {
      let linkIcon = getExternalLinkIcon('untrusted');
      tooltipContent = `${linkIcon}${htmlToTextContent(externalLinkHref || '')}`;
      tooltipElement.classList.add('untrusted-domain');
      tooltipElement.classList.remove('malicious-link');
      tooltipElement.classList.remove('blocked-link');
    }
  } else {
    let linkIcon = getExternalLinkIcon('trusted');
    tooltipContent = `${linkIcon}${getTrustedDomainTooltipPath(tooltipURL)}`;
    tooltipElement.classList.remove('untrusted-domain');
    tooltipElement.classList.remove('malicious-link');
    tooltipElement.classList.remove('blocked-link');
  }
  let linkBoundingRect = linkElement.getBoundingClientRect();
  tooltipElement.innerHTML = sanitizeHtml(tooltipContent);
  tooltipElement.style.maxWidth = '35vw';
  tooltipElement.style.top = `${linkBoundingRect.top}px`;
  tooltipElement.setAttribute('data-show', '');
  // Position the tooltip in the middle of the link
  tooltipElement.style.left = `${
    linkBoundingRect.left + linkElement.offsetWidth / 2 - tooltipElement.offsetWidth / 2
  }px`;

  // Keyboard shortcuts can be invoked while the mouse is over a link, so we need to listen to keydown events and hide the tooltip
  hideTooltipOnKeydown();
  // Mouse scroll should also hide the tooltip
  hideTooltipOnMouseScroll();
}

function hideExternalLinkTooltip() {
  let tooltipElement = document.getElementById('external-link-tooltip');
  if (!tooltipElement) {
    return;
  }

  tooltipElement.removeAttribute('data-show');
  tooltipElement.classList.remove('opacity-100');

  if (listeningToKeydown) {
    document.removeEventListener('keydown', hideExternalLinkTooltip, true);
    listeningToKeydown = false;
  }

  if (listeningToMouseScroll) {
    document.removeEventListener('scroll', hideExternalLinkTooltip, true);
    listeningToMouseScroll = false;
  }
}

function getTrustedDomainTooltipPath(url: URL) {
  if (['mailto:', 'tel:'].includes(url.protocol)) {
    return url.pathname;
  }

  return url.href;
}

function arraysAreEqual(a1: unknown[], a2: unknown[]) {
  if (a1.length !== a2.length) {
    return false;
  }
  return a1.every((item, index) => item === a2[index]);
}

function domainMatchesTrustedDomainPieces(domain: string, trustedDomainPieces: string[]) {
  // Assumes `trustedDomainPieces[0] === '*'`, so ignore first elem
  let trustedSuffix = trustedDomainPieces.slice(1);
  let domainPieces = domain.split('.');
  let domainSuffixToCheck = domainPieces.slice(-trustedSuffix.length);
  return arraysAreEqual(domainSuffixToCheck, trustedSuffix);
}

function urlMatchesMaliciousLink(url: string, maliciousLinks: string[]) {
  let normalizedUrl = url.replace(/^https?:\/\//, '').replace(/\/$/, '');
  return maliciousLinks.includes(normalizedUrl);
}

function domainMatchesPolicyItem(domain: string, policyItemDomain: string) {
  let policyItemDomainPieces = policyItemDomain.split('.');
  if (policyItemDomainPieces[0] === '*') {
    return domainMatchesTrustedDomainPieces(domain, policyItemDomainPieces);
  } else {
    if (domain.startsWith('www.') && domain.split('.').length > 2) {
      domain = domain.substring(4);
    }
    if (policyItemDomain.startsWith('www.') && policyItemDomain.split('.').length > 2) {
      policyItemDomain = policyItemDomain.substring(4);
    }
    return domain === policyItemDomain;
  }
}

function urlMatchesPolicyItem(url: string, urlPolicyItem: string) {
  let normalizedUrl = url.replace(/^https?:\/\//, '').replace(/\/$/, '');
  let normalizedUrlPolicyItem = urlPolicyItem.replace(/^https?:\/\//, '').replace(/\/$/, '');

  return normalizedUrl === normalizedUrlPolicyItem;
}

function urlMatchesAnyDomainOrUrlPolicy(
  url: string,
  type: 'trusted' | 'blocked',
  domainsAndUrls: TeammateLinkDomainPolicy[],
) {
  try {
    let hostname = new URL(url).hostname;
    return domainsAndUrls.some((policyItem) => {
      if (policyItem.policy_action !== type) {
        return false;
      }
      if (policyItem.policy_type === 'domain') {
        return domainMatchesPolicyItem(hostname, policyItem.item);
      } else if (policyItem.policy_type === 'url') {
        return urlMatchesPolicyItem(url, policyItem.item);
      }
      return false;
    });
  } catch {
    return false;
  }
}

export default class RenderHtmlHelper extends Helper {
  @service declare regionService: RegionService;
  @service declare intl: IntlService;
  @service
  declare inbox2TrustedDomainsForInboundEmailLinks: Inbox2TrustedDomainsForInboundEmailLinks;

  compute(
    [html]: [string],
    {
      parseNewlines,
      highlight,
      supportedTags,
      supportedAttributes,
      renderAsciiEmoji,
      imageAsText = false,
      externalLinkShield = false,
      externalDomains = [],
      trustedDomains = [],
      trustedDomainsAndUrls = [],
      blockedDomainsAndUrls = [],
      blockedDomainsAndUrlsEnabled = false,
      maliciousLinks = [],
      maliciousLinkBlockingEnabled = false,
      appId,
    }: {
      parseNewlines?: boolean;
      highlight?: string[];
      supportedTags?: string[];
      supportedAttributes?: string[];
      renderAsciiEmoji?: boolean;
      imageAsText?: boolean;
      externalLinkShield?: boolean;
      externalDomains?: string[];
      trustedDomains?: string[];
      trustedDomainsAndUrls?: TeammateLinkDomainPolicy[];
      blockedDomainsAndUrls?: TeammateLinkDomainPolicy[];
      blockedDomainsAndUrlsEnabled?: boolean;
      maliciousLinks?: string[];
      maliciousLinkBlockingEnabled?: boolean;
      appId?: string;
    } = {},
  ) {
    if (parseNewlines) {
      html = parseDoubleNewlines(html);
    }

    let htmlCleaner = new HtmlCleaner(supportedTags, supportedAttributes);
    let cleanedHtml = htmlCleaner.cleanHtml(html);
    let emojifiedHTml = emoji.emojify(cleanedHtml, renderAsciiEmoji);
    let wrappedHtml = wrapInContainer(emojifiedHTml, imageAsText, this.intl);

    trustedDomains = this.inbox2TrustedDomainsForInboundEmailLinks.trustedDomains
      .concat(externalDomains)
      .map((url: string) => url.replace('https://', ''));

    addExternalLinkShield(
      wrappedHtml,
      externalLinkShield,
      maliciousLinks,
      maliciousLinkBlockingEnabled,
      trustedDomains,
      trustedDomainsAndUrls,
      blockedDomainsAndUrls,
      blockedDomainsAndUrlsEnabled,
      appId,
    );

    if (highlight?.length) {
      highlightText(wrappedHtml, highlight);
    }

    return wrappedHtml;
  }
}
