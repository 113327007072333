/* RESPONSIBLE TEAM: team-reporting */
import { select } from 'd3-selection';
import 'd3-transition';
import PALETTE from '@intercom/pulse/lib/palette';

export class BubbleChartSatisfaction {
  csatColorScale;
  showSatisfaction = false;

  animateColorChange(selection, labelFillColor, bubbleFillColor, bubbleStrokeColor) {
    selection
      .select('.reporting__bubble-chart__label')
      .transition()
      .duration(500)
      .style('fill', labelFillColor);

    selection
      .select('.reporting__bubble-chart__bubble')
      .transition()
      .duration(500)
      .style('fill', bubbleFillColor)
      .style('stroke', bubbleStrokeColor);
  }

  // Show CSAT by coloring bubbles appropriately.
  turnCSATon(nodes, externalLabels) {
    let csatColorScale = this.csatColorScale;
    let animateColorChange = this.animateColorChange;

    nodes.each(function (d) {
      let positiveRate = d.currentPositiveRate;
      let bubbleFillColor =
        positiveRate !== null
          ? csatColorScale(positiveRate)
          : PALETTE['neutral-container-emphasis'];
      let bubbleStrokeColor = bubbleFillColor;

      animateColorChange(select(this), 'black', bubbleFillColor, bubbleStrokeColor);
    });

    externalLabels.classed('o__with-csat', true);
  }

  // Return bubbles to original color.
  turnCSAToff(nodes, externalLabels) {
    nodes.call(this.animateColorChange, null, null, null);
    externalLabels.classed('o__with-csat', false);
  }

  updateCsatColoring(nodes, externalLabels) {
    if (this.showSatisfaction) {
      this.turnCSATon(nodes, externalLabels);
    } else {
      this.turnCSAToff(nodes, externalLabels);
    }
  }

  toggleSatisfaction(element, showSatisfaction) {
    this.showSatisfaction = showSatisfaction;
    let nodes = select(element).selectAll('.reporting__bubble-chart__node');
    let externalLabels = select(element).selectAll('.reporting__bubble-chart__external-label');

    this.updateCsatColoring(nodes, externalLabels);
  }

  init(csatColorScale) {
    this.csatColorScale = csatColorScale;
  }
}
